<template>
  <main
      v-if="(facility && localPaymentRequest) && (isParent || !isFacilityProvider)"
      class="page page--narrow"
      :class="{'pt-2': !isParent}"
  >
    <div v-if="showSummary"
      class="page__content"
    >
      <h5 class="page__header">
        {{ facility.name }} has requested a payment
        {{ dependentName ? `for ${dependentName}` : 'from you' }}.
      </h5>
      <section class="page__body">
        <template>
          <simple-facility-card
              :key="facility?.id"
              :facility-data="facility"
              :is-summary="false"
              :is-claimable="false"
              :is-editable="false"
              class="facility-card"
          />
          <div v-if="localPaymentRequest.providerMessage" class="provider-message">
            <i class="fal fa-comment-alt-lines provider-message--icon"></i>
            {{ localPaymentRequest.providerMessage }}
          </div>
          <div class="payment-request-items" v-if="paymentRequest?.paymentRequestItems?.length > 1">
            <div
              class="payment-request-item"
              v-for="paymentRequestItem in paymentRequest?.paymentRequestItems"
              :key="paymentRequestItem.id">
              <div>{{ paymentRequestItem.text }}</div>
              <div>{{ getDollarFormat(paymentRequestItem.amount) }}</div>
            </div>
            <div class="payment-request-item payment-request-item--total">
              <div>Total</div>
              <div>{{ requestedAmount }}</div>
            </div>
          </div>
          <div class="payment-details" v-else>
            <h1>{{ requestedAmount }}</h1>
            <p class="category">{{ paymentRequest?.category }}</p>
          </div>
          <div class="align-center">
            <system-button
                :style-type="'primary'"
                @click="getStarted"
            >
              Get started
            </system-button>
          </div>
          <div class="decline">
            <p>Something not look right?</p>
            <p @click="openDeclineForm" class="decline-link">Decline this request</p>
          </div>
          <div class="secure-connection">
            <span>
              <i class="fa far fa-lock"></i>
              <span>Secure connection</span>
            </span>
          </div>
        </template>
      </section>
      <template v-if="loading">
        <loader :loading="loading" />
      </template>
      <decline-form
        :isOpen="isDeclineFormOpen"
        :closeModal="closeDeclineForm"
        :paymentRequest="localPaymentRequest"
      />
    </div>
    <div v-if="showDeclineConfirmation" class="decline-container">
      <img class="image" :src="blocksUrl" >
      <h4 class="mt-2"> You declined this request.</h4>
      <p class="mt-2 feedback"> Thanks for your feedback. The provider will be notified.</p>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import { getDollarFormat, trackEvent } from '@utils';
import { illustrations } from '@images';
import Loader from '@components/loader/loader.vue';
import SystemButton from '@components/buttons/system-button.vue';
import SimpleFacilityCard from '@components/simple-facility-card/simple-facility-card.vue';
import DeclineForm from '@parent/components/payments/request-payment/decline-form.vue';

export default {
  name: 'payment-request-summary',
  components: {
    SimpleFacilityCard,
    Loader,
    SystemButton,
    DeclineForm,
  },
  data() {
    return {
      loading: false,
      enableSendPayment: true,
      isDeclineFormOpen: false,
      localPaymentRequest: null,
    };
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facilityInfo',
      paymentRequest: 'payments/payMeRequest',
      user: 'user/currentParent',
    }),
    requestedAmount() {
      return getDollarFormat(this.localPaymentRequest.amount);
    },
    isParent() {
      return !this.user || this.user.isParent;
    },
    isFacilityProvider() {
      return this.user.isProvider
        && this.facility?.provider_id === this.$store.state.user.current.providerId;
    },
    showDeclineConfirmation() {
      return this.localPaymentRequest.aasmState === 'canceled'
        && this.localPaymentRequest.declineReason !== null;
    },
    showSummary() {
      return this.localPaymentRequest.aasmState === 'requested'
        && this.localPaymentRequest.declineReason === null;
    },
    blocksUrl() {
      return illustrations.block5;
    },
    dependentName() {
      return this.localPaymentRequest?.dependent.firstName;
    },
  },
  watch: {
    paymentRequest: {
      handler() {
        this.getPaymentRequestFromStore();
      },
      immediate: true,
    },
  },
  methods: {
    getDollarFormat,
    getPaymentRequestFromStore() {
      this.localPaymentRequest = { ...this.paymentRequest };
    },
    async getStarted() {
      if (!this.enableSendPayment) {
        return;
      }
      trackEvent(Event.buttonClicked, {
        event_description: 'User clicked to start payment flow from the pay me request summary page',
        button_label: 'Get started',
        button_location: 'payment-request-summary',
        user_type: 'parent',
      });
      this.$router.push({ name: 'send-payment', query: { pr_id: this.$route.query.pr_id } });
    },
    openDeclineForm() {
      trackEvent(Event.buttonClicked, {
        event_description: 'User clicked to decline the payment request',
        button_label: 'Decline this request',
        button_location: 'payment-request-summary',
        user_type: 'parent',
      });
      this.isDeclineFormOpen = true;
    },
    closeDeclineForm() {
      this.isDeclineFormOpen = false;
    },
    async fetchPaymentRequest() {
      await this.$store.dispatch(
        'payments/fetchPayMeRequest',
        {
          id: `${this.$route.query.pr_id}`,
          facilityId: this.facility?.id,
        },
      );
    },
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch(
      'facility/fetchFacilityDetails',
      `${this.$route.params.state}/${this.$route.params.city}/${this.$route.params.facilityFriendlyId}`,
    );
    await this.fetchPaymentRequest();

    if (!this.facility.enable_send_payment) {
      this.enableSendPayment = false;
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>

.page {
  --max-page-width: 720px;

  &--narrow {
    --max-page-width: 490px;
  }

  &:last-of-type {
    margin-bottom: calc(var(--grid-unit) * 15);

    @media screen and (max-width: 768px) {
      margin-bottom: calc(var(--grid-unit));
    }
  }

  &__header {
    display: revert;
    text-align: center;
    padding: calc(var(--grid-unit) * 2);
    font-weight: 700;
    border-radius: calc(var(--grid-unit) * 4) calc(var(--grid-unit) * 4) 0 0;

    @media screen and (min-width: 400px) {
      padding: calc(var(--grid-unit) * 4);
    }
  }

  &__content {
    max-width: var(--max-page-width);
    background: var(--gray-0);
    font-size: calc(var(--grid-unit) * 2);
    min-height: 300px;
    margin: calc(var(--grid-unit) * 5) auto;
    border-radius: calc(var(--grid-unit) * 4);

    @media screen and (max-width: 768px) {
      width: 100vw;
    }
  }

  &__body {
    padding: calc(var(--grid-unit) * 2);

    @media screen and (min-width: 400px) {
      padding: calc(var(--grid-unit) * 4);
    }

    @media screen and (min-width: 768px) {
      padding: calc(var(--grid-unit) * 4) calc(var(--grid-unit) * 6);
    }

    > * {
      margin-bottom: calc(var(--grid-unit) * 2);
    }
  }

  .provider-message {
    background: var(--viridian-0);
    color: var(--viridian-70);
    padding: var(--grid-unit);
    text-align: center;
    min-width: 50%;
    max-width: 65%;
    margin: 0 auto;
    margin-bottom: calc(var(--grid-unit) * 3);
    border-radius: var(--radius);

    &--icon {
      margin-right: var(--grid-unit);
    }
  }

  .payment-details {
    text-align: center;
    border: var(--border) var(--gray-80);
    border-radius: var(--radius-big);
    padding: calc(var(--grid-unit) * 4);
    margin: calc(var(--grid-unit) * 4) 0;
    width: 100%;

    .category {
      margin-top: calc(var(--grid-unit) * 2);
      font-size: var(--font-size-10);

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .decline {
    text-align: center;
    margin: calc(var(--grid-unit) * 4);
  }

  .decline-link {
    color: var(--viridian-50);
    cursor: pointer;
  }

  .secure-connection {
    color: #616161;
    font-size: calc(var(--grid-unit) * 2);
    text-align: center;
  }
}

.decline-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: calc(var(--grid-unit) * 4);
  margin: auto;
  margin-top: calc(var(--grid-unit) * 5);
  width: 480px;
  background-color: var(--gray-0);
  border-radius: calc(var(--grid-unit) * 2);

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.feedback {
  font-size: var(--font-size-20);
}

.image {
  width: calc(var(--grid-unit) * 12);
  height: calc(var(--grid-unit) * 10);
}

.facility-card {
  margin-bottom: calc(var(--grid-unit) * 3);
}

.payment-request-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: calc(var(--grid-unit) * 2);
  align-items: center;
  border-bottom: 1px solid var(--gray-15);
  padding-bottom: calc(var(--grid-unit) / 2);
  margin-bottom: calc(var(--grid-unit) / 2);

  &--total {
    div:last-child {
      font-weight: 500;
      font-size: 21px
    }
  }

  & div:last-child {
    text-align: right;
  }
}

.payment-request-items {
  border-radius: calc(var(--grid-unit) * 2);
  padding: calc(var(--grid-unit) * 2);
  border: 1px solid var(--gray-15);
  margin-bottom: calc(var(--grid-unit) * 3);

  .payment-request-item:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
</style>
