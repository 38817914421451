<template>
  <div class="special-box" :class="colorClass">
    <tag class="special-box__tag" :color="color"><slot name="tag"></slot></tag>
    <p><slot></slot></p>
  </div>
</template>

<script>
import Tag from '@components/tag/tag.vue';

export default {
  components: {
    Tag,
  },
  props: {
    color: {
      type: String,
      default: 'gold',
    },
  },
  computed: {
    colorClass() {
      return `special-box--${this.color}`;
    },
  },

};
</script>

<style lang="scss" scoped>
.special-box {
  position: relative;
  padding: calc(var(--grid-unit) * 3);
  border-radius: var(--radius-big);

  &--gold {
    color: var(--gold-80);
    background: var(--gold-0);
  }

  &--gray {
    color: var(--gray-80);
    background: var(--gray-5);
  }

  &--coral {
    color: var(--coral-80);
    background: var(--gray-0);
    background-color: var(--coral-10);
  }

  &__tag {
    margin-bottom: var(--grid-unit);
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}
</style>
