<template>
  <div class="enrollment-box" :class="colorClass">
    <div class="enrollment-box__icon">
      <i :class="icon"></i>
    </div>
    <div class="enrollment-box__data">
      <div class="enrollment-box__name-status">
        <h6>{{enrollment.dependent.nameAge}}</h6>
        <tag :color="color">{{enrollment.aasmState}}</tag>
      </div>
      <div v-if="enrollment.facilityProgram">{{enrollment.facilityProgram.name}}</div>
      <div>{{price}}</div>
      <div>Starting on {{date}}</div>
    </div>
  </div>
</template>

<script>
import { getDollarFormat } from '@utils';
import Tag from '@components/tag/tag';

export default {
  components: {
    Tag,
  },
  props: {
    enrollment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    price() {
      return `${getDollarFormat(this.enrollment.priceCents)}/${this.enrollment.priceUnit}`;
    },

    date() {
      return this.$moment(this.enrollment.startDate).format('MMM Do, YYYY');
    },

    icon() {
      return this.enrollment.dependent.nameAge.includes('mont') ? 'fal fa-baby' : 'fal fa-child';
    },

    color() {
      return 'gold';
    },

    colorClass() {
      return `enrollment-box--${this.color}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.enrollment-box {
  display: grid;
  grid-template-columns: 40px 1fr;
  overflow: hidden;
  text-align: left;
  border: var(--border) var(--gray-80);
  border-radius: var(--radius-big);

  + .enrollment-box {
    margin-top: var(--grid-unit);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }

  &__data {
    padding: var(--grid-unit);
  }

  &__name-status {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    h6 {
      flex: 0 1 80%;
      margin-right: calc(var(--grid-unit) * 2);
    }
  }

  &--gold .enrollment-box {
    &__icon {
      background: var(--gold-20);
    }
  }

  &--viridian .enrollment-box {
    &__icon {
      background: var(--viridian-5);
    }
  }

  &--gray .enrollment-box {
    &__icon {
      background: var(--gray-5);
    }
  }
}
</style>
