<template>
  <Tooltip
    theme="kinside-info"
    :class="[
      'preview-mode-tooltip',
      name ? `preview-mode-tooltip--${name}` : null,
    ]"
    :disabled="!$store.getters.isPreviewMode"
    :triggers="['hover', 'click', 'touch']"
  >
    <slot></slot>
    <template #popper>
      {{tooltipText}}
    </template>
  </Tooltip>
</template>

<script>
import { Tooltip } from 'floating-vue';

export default {
  components: {
    Tooltip,
  },
  props: {
    tooltipText: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-mode-tooltip {
  width: 100%;
}
</style>
