<template>
  <div class="opinion-bar"
    :class="'opinion-bar--' + position"
  >
    <opinion-bar-button-wrapper
      tooltip-label="Parent opinion"
      tooltip-text="This is where a parent will indicate how
      well your facility matches what they’re looking for."
    >
      <button
        class="opinion-bar__button"
        :class="{'opinion-bar__button--favorited': isFavorited}"
        v-on="!isPreviewMode ? { click: toggleFavorited } : {}"
      >
        <i class="fal fa-heart" :class="{'fas': isFavorited}"></i>
        <span>Great fit!</span>
      </button>
    </opinion-bar-button-wrapper>
    <opinion-bar-button-wrapper
      tooltip-label="Parent opinion"
      tooltip-text="This is where a parent will indicate how
      well your facility matches what they’re looking for."
    >
      <button
        class="opinion-bar__button"
        :class="{'opinion-bar__button--saved': isSaved}"
        v-on="!isPreviewMode ? { click: toggleSaved } : {}"
      >
        <i class="fal fa-thumbs-up" :class="{'fas': isSaved}"></i>
        <span>Maybe</span>
      </button>
    </opinion-bar-button-wrapper>
    <opinion-bar-button-wrapper
      tooltip-label="Parent opinion"
      tooltip-text="This is where a parent will indicate how
      well your facility matches what they’re looking for."
    >
      <button
        class="opinion-bar__button"
        :class="{'opinion-bar__button--ignored': isIgnored}"
        v-on="!isPreviewMode ? { click: toggleIgnored } : {}"
      >
        <i class="fal fa-thumbs-down" :class="{'fas': isIgnored}"></i>
        <span>Not a fit</span>
      </button>
    </opinion-bar-button-wrapper>
  </div>
</template>

<script>
import OpinionBarButtonWrapper from '@components/opinion-bar/opinion-bar-button-wrapper.vue';

export default {
  components: {
    OpinionBarButtonWrapper,
  },
  props: {
    position: {
      type: String,
      default: 'top',
      validator: (value) => ['top', 'bottom'].includes(value),
    },
    isFavorited: {
      type: Boolean,
    },
    isSaved: {
      type: Boolean,
    },
    isIgnored: {
      type: Boolean,
    },
  },
  computed: {
    isPreviewMode() {
      return this.$store.getters.isPreviewMode;
    },
  },
  methods: {
    toggleFavorited() {
      return this.$emit('toggle-opinion', 'Favorited');
    },
    toggleSaved() {
      return this.$emit('toggle-opinion', 'Saved');
    },
    toggleIgnored() {
      const currentRoute = this.$router.currentRoute.name;
      if (currentRoute === 'facility-profile') {
        this.$emit('toggle-opinion', 'Ignored');
        return;
      }

      document.getElementById('facilities-content').classList.add('add-fade-out');
      setTimeout(() => {
        document.getElementById('facilities-content').classList.remove('add-fade-out');
      }, 1200);

      this.$emit('toggle-opinion', 'Ignored');
    },
  },
};
</script>

<style lang="scss" scoped>
.opinion-bar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: calc(var(--grid-unit) * 5);
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  @media (min-width: 400px) {
    height: calc(var(--grid-unit) * 6);
  }

  &__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    color: var(--gray-80);
    background: none;
    border: none;

    & span {
      margin-left: calc(var(--grid-unit) / 2);

      @extend .font-body-small !optional;
    }

    @media (min-width: 400px) {
      & span {
        font: inherit;
      }
    }

    &--favorited {
      color: var(--coral-80);
      background: var(--coral-5);

      @media (min-width: 400px) {
        border-top-left-radius: calc(var(--grid-unit) * 2);
        border-bottom-left-radius: inherit;
      }

      .fas {
        color: var(--coral-20);
      }
    }

    &--ignored {
      background: var(--gray-5);

      @media (min-width: 400px) {
        border-top-right-radius: calc(var(--grid-unit) * 2);
        border-bottom-right-radius: inherit;
      }

      .fas {
        color: var(--gray-50);
      }
    }

    &--saved {
      color: var(--periwinkle-80);
      background: var(--periwinkle-5);

      .fas {
        color: var(--periwinkle-20);
      }
    }
  }
}

.opinion-bar--bottom {
  .opinion-bar__button--favorited {
    border-top-left-radius: 0;
    border-bottom-left-radius: calc(var(--grid-unit) * 2);
  }

  .opinion-bar__button--ignored {
    border-top-right-radius: 0;
    border-bottom-right-radius: calc(var(--grid-unit) * 2);
  }
}
</style>
