<template>
  <figure
    v-if="!isInitial"
    class="avatar"
    :class="`avatar--${size} avatar--${shape}`"
    :style="{ 'background-image': 'url('+backgroundImage+')' }"
  >
  </figure>
  <figure
    v-else
    class="avatar avatar-initials"
    :class="`avatar--${size} avatar--${shape} avatar--${initialColor}`"
  >
    {{ initial }}
  </figure>
</template>

<script>
import { misc } from '@images';

export default {
  name: 'avatar',
  props: {
    src: {
      type: String,
      default: null,
    },
    shape: {
      type: String,
      default: 'circle',
      validator(value) {
        return ['circle', 'square'].indexOf(value) !== -1;
      },
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['small', 'medium', 'large', 'xlarge'].indexOf(value) !== -1;
      },
    },
    isCloudinaryLink: {
      type: Boolean,
      default: false,
    },
    isInitial: {
      type: Boolean,
      default: false,
    },
    initial: {
      type: String,
      default: null,
    },
    initialColor: {
      type: String,
      default: 'periwinkle',
      validator(value) {
        return [
          'gold',
          'periwinkle',
          'coral',
          'fuchsia',
          'rust',
        ].includes(value);
      },
    },
  },
  computed: {
    dimensions() {
      const dimensions = {
        small: 32,
        medium: 64,
        large: 80,
        xlarge: 120,
      };
      return dimensions[this.size];
    },
    cloudinaryFilters() {
      return {
        width: this.dimensions,
        height: this.dimensions,
        qualityAuto: 'best',
      };
    },
    cloudinaryLink() {
      return this.$cloudinarize(this.src, this.cloudinaryFilters);
    },
    backgroundImage() {
      if (!this.src) {
        return this.$cloudinarize(misc.avatar, this.cloudinaryFilters);
      }
      return this.isCloudinaryLink ? this.cloudinaryLink : this.src;
    },
  },
};
</script>

<style lang="scss" scoped>
  .avatar {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    background: var(--viridian-5);
    background-position: center;
    background-size: cover;
    border-radius: 50%;
  }

  .avatar--square {
    border-radius: calc(var(--grid-unit) * 2.5);
  }

  .avatar--xlarge {
    width: 120px;
    height: 120px;
  }

  .avatar--large {
    width: 64px;
    height: 64px;

    @media screen and (min-width: 1200px) {
      width: 80px;
      height: 80px;
    }
  }

  .avatar--medium {
    width: 48px;
    height: 48px;

    @media screen and (min-width: 1200px) {
      width: 64px;
      height: 64px;
    }
  }

  .avatar--small {
    width: 32px;
    height: 32px;
  }

  .avatar-initials {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: calc(var(--grid-unit) * 3);
    font-weight: 500;
  }

  .avatar--periwinkle {
    color: var(--periwinkle-80);
    background-color: var(--periwinkle-20);
  }

  .avatar--rust {
    color: var(--rust-80);
    background-color: var(--rust-20);
  }

  .avatar--gold {
    color: var(--gold-80);
    background-color: var(--gold-20);
  }

  .avatar--coral {
    color: var(--coral-80);
    background-color: var(--coral-20);
  }

  .avatar--fuchsia {
    color: var(--fuchsia-80);
    background-color: var(--fuchsia-20);
  }
</style>
