<template>
  <div
    class="facilityTourStatus"
    :class="stateClass"
  >
    <div class="facilityTourStatus__icon fal"
      :class="statusIconClass">
    </div>
    <div class="facilityTourStatus__content">
      <h6
        class="facilityTourStatus__header"
        v-html="statusHeader">
      </h6>
      <p class="facilityTourStatus__body">
        {{ statusBody }}
      </p>
      <system-button
        :style-type="buttonStyleType"
        class="facilityTourStatus__button"
        color="periwinkle"
        small
        @click="onClick"
        :fa-icon="buttonIcon"
        :disabled="disabled"
      >
        {{ btnLabel }}
      </system-button>
    </div>
  </div>
</template>

<script>
import SystemButton from '../buttons/system-button.vue';
import timeZoneAbbr from '../../mixins/timeZoneAbbr';

const buttonLabels = {
  reserved: 'Modify your request',
  confirmed: 'View details',
  rescheduleNeeded: 'Reschedule tour',
  completed: 'Schedule another tour',
};

export default {
  name: 'tour-status',
  components: {
    SystemButton,
  },

  props: {
    reservation: {
      type: Object,
      default: null,
    },
    unclaimed: {
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [timeZoneAbbr],
  computed: {
    state() {
      return this.reservation.aasmState;
    },

    stateClass() {
      return `facilityTourStatus--${this.state}`;
    },

    dateAndTime() {
      if (!this.reservation) {
        return '';
      }
      const startTime = this.$moment.unix(this.reservation.startAt).tz(this.reservation.timezone);
      const format = (this.state !== 'completed') ? 'ddd MMM D [@] h:mma' : 'MMM D YYYY';
      return startTime.format(format);
    },

    isPrimaryButton() {
      return this.state === 'rescheduleNeeded';
    },

    buttonStyleType() {
      return this.isPrimaryButton ? 'primary' : 'secondary';
    },

    buttonIcon() {
      return this.isPrimaryButton ? 'calendar-check' : undefined;
    },

    statusIconClass() {
      const icons = {
        reserved: 'fa-calendar-day',
        confirmed: 'fa-calendar-check',
        rescheduleNeeded: 'fa-calendar-times',
        completed: 'fa-calendar-check',
      };
      return icons[this.state];
    },

    headerTemplates() {
      return {
        reserved: `Tour requested for ${this.dateAndTime} ${this.timeZoneAbbr}`,
        confirmed: `Tour confirmed for ${this.dateAndTime} ${this.timeZoneAbbr}`,
        rescheduleNeeded: 'Please reschedule',
        completed: 'Tour completed',
      };
    },

    statusHeader() {
      return this.headerTemplates[this.state];
    },

    bodyTemplates() {
      return {
        reserved: 'Your request has been sent to this location\'s team. We will let you know as soon as they have confirmed the reservation.',
        confirmed: 'Your tour has been confirmed! Please mark the the date and time in your calendar.',
        rescheduleNeeded: `You requested a tour on ${this.dateAndTime}, but unfortunately it is unavailable. Please propose an alternative time.`,
        completed: `You toured this location on ${this.dateAndTime}`,
      };
    },

    statusBody() {
      return this.bodyTemplates[this.state];
    },

    btnLabel() {
      const fallback = this.unclaimed ? 'Request a tour' : 'Book a tour';
      return buttonLabels[this.state] || fallback;
    },
  },

  methods: {
    onClick() {
      this.$emit('schedule-tour');
    },
  },
};
</script>

<style lang="scss" scoped>

  .facilityTourStatus {
    display: flex;
    overflow: hidden;
    border: var(--border) var(--gray-60);
    border-radius: var(--radius-big);

    &__icon {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: calc(var(--grid-unit) * 5);
      font-size: 24px;
    }

    &__header {
      display: flex;

      .fal {
        margin-right: var(--grid-unit);
      }
    }

    &__content {
      padding: var(--grid-unit);
    }

    &--reserved {
      .facilityTourStatus__icon {
        color: var(--periwinkle-80);
        background: var(--periwinkle-0);
      }
    }

    &--completed {
      .facilityTourStatus__icon {
        color: var(--gray-80);
        background: var(--gray-0);
      }
    }

    &--confirmed {
      .facilityTourStatus__icon {
        color: var(--periwinkle-80);
        background: var(--periwinkle-20);
      }
    }

    &--rescheduleNeeded {
      .facilityTourStatus__icon {
        color: var(--rust-80);
        background: var(--rust-20);
      }
    }
  }

</style>
