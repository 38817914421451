<template>
  <div>
    <k-form-field label="Which child are you joining the waitlist for?">
      <dependents-radio-list v-if="!multiDependents"
        :parentId="parentId"
        v-model="dependentId"
        :ageDisplay=false
        :olderChildren="olderChildren"
        :dependentsWithSpots="updatedDependentsWithSpots"
      />
    </k-form-field>
    <k-form-field label="When do you want to start care?">
      <date-input v-model="startCare" min="today" :max="after5Years()" />
    </k-form-field>
    <k-form-field
      label="Phone number"
      rules="required|mobileNumber"
    >
      <k-input
        rules="required"
        v-model="phoneNumber"
        placeholder="5555555555"
      />
    </k-form-field>
    <div class="textarea-container">
      <k-form-field label="Any additional info you'd like to provide? (optional)">
        <k-textarea
          v-model="parentComment"
          id="waitlist-note"
          :maxlength="maxTextLength"
          @input="handleInput"
        >
        </k-textarea>
        <small>{{ remainingCharacters }} characters remaining</small>
      </k-form-field>
    </div>
  </div>
</template>

<script>
import DateInput from '@components/inputs/date-input.vue';
import KFormField from '@components/forms/form_field.vue';
import KInput from '@components/inputs/input.vue';
import KTextarea from '@components/inputs/textarea.vue';
import DependentsRadioList from '@parent/components/dependent-management/dependents-radio-list.vue';
import addYears from '@utils/addYears';

export default {
  components: {
    DateInput,
    KFormField,
    KInput,
    KTextarea,
    DependentsRadioList,
  },
  props: {
    multiDependents: {
      type: Boolean,
      default: false,
    },
    waitlist: {
      type: Object,
      default() {
        return {};
      },
    },
    bus: {
      type: Object,
      required: true,
    },
    submitStep: {
      type: Function,
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    olderChildren: {
      type: Array,
      default() {
        return [];
      },
    },
    dependentsWithSpots: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      dependentId: this.waitlist.waitlistData.dependentId || undefined,
      startCare: this.waitlist.waitlistData.startCare || new Date(),
      phoneNumber: this.waitlist.waitlistData.phoneNumber || '',
      parentComment: this.waitlist.waitlistData.parentComment || '',
      errors: [],
      updatedDependentsWithSpots: this.dependentsWithSpots || [],
      maxTextLength: 512,
    };
  },
  created() {
    this.checkForWaitlistSpots();
    this.bus.$on('on-next-step', this.handleNext);
  },
  beforeDestroy() {
    this.bus.$off('on-next-step', this.handleNext);
  },
  watch: {
    dependentId() {
      this.onChange(this.$data);
    },
    parentComment() {
      this.onChange(this.$data);
    },
    phoneNumber() {
      this.onChange(this.$data);
    },
    startCare() {
      this.onChange(this.$data);
    },
  },
  computed: {
    remainingCharacters() {
      return this.maxTextLength - this.parentComment.length;
    },
    parentId() {
      const user = this.$store.state.user.current;
      return user?.isParent ? user.id : undefined;
    },
  },
  methods: {
    handleNext() {
      this.submitStep();
    },
    handleInput() {
      if (this.parentComment.length > this.maxTextLength) {
        // Truncate the parentComment to the maximum length
        this.parentComment = this.parentComment.slice(0, this.maxTextLength);
      }
    },
    async checkForWaitlistSpots() {
      const waitlistSpots = await this.$store.dispatch('getWaitlistSpots');
      if (waitlistSpots?.length) {
        const centerSpots = waitlistSpots.filter((ws) => (
          ws.center_id === this.$store.state.legup.centerId
        ));
        if (centerSpots.length) {
          const spotChildIds = centerSpots.map((cs) => cs.child_id);
          const dependentIds = Object.values(this.$store.state.dependents.list).map((dep) => (
            dep.legupChildId
          ));
          this.updatedDependentsWithSpots = dependentIds.filter((id) => spotChildIds.includes(id));
        }
      }
    },
    after5Years() {
      return addYears(new Date(), 5);
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea-container {
  margin-top: calc(var(--grid-unit) * 3);
}
</style>
