import authMixin from '@profile/mixins/auth';
import friendlyId from '@profile/utils/friendlyId';
import facilityProfileVuexProvider from '@parent/providers/facilityProfileVuexProvider';
import store from '@parent/stores/parent.store';

export default {
  mixins: [
    authMixin,
  ],

  data() {
    return {
      provider: facilityProfileVuexProvider(store),
      loading: false,
    };
  },

  async mounted() {
    if (!this.facility?.id || this.facility.friendlyId !== this.friendlyId) {
      await this.loadFacility();
    }

    this.fetchLegupData();
  },

  computed: {
    facility() {
      return this.provider.currFacility;
    },

    showContent() {
      return !this.isAuthorized ? true : !this.loading && !!this.facility;
    },

    showLegupActions() {
      if (!this.isAuthorized) {
        return true;
      }

      return this.facility.legupCenterId && this.facility.legupOnboarded;
    },
    friendlyId,
  },

  methods: {
    async loadFacility() {
      this.loading = true;
      if (this.isAuthorized) {
        await this.provider.loadFacility(this.friendlyId);
      }
      this.loading = false;
    },

    async trackLegupActionClick(action) {
      await this.$store.dispatch('track/event', {
        category: 'marketplace',
        event: 'click_legup_action_link',
        props: { ...this.eventTrackingProperties, action },
      });
    },

    async fetchLegupData() {
      if (this.facility?.legupOnboarded && this.facility?.legupCenterId) {
        if (this.isAuthorized) {
          await this.$store.dispatch('legup/providerWaitlistFees', { id: this.facility.legupCenterId });
          await this.$store.dispatch('legup/findCenterInfo', this.facility.legupCenterId);
          await this.$store.dispatch('legup/findCenterQuestions', { id: this.facility.legupCenterId, form: 'waitlist' });
          await this.$store.dispatch('legup/checkForProviderCoupons', { center_id: this.facility.legupCenterId });
        }
      }
    },
  },
};
