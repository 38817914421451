<template>
  <vue-timepicker
    v-model="localValue"
    :disabled="disabled"
    :placeholder="placeholder"
    :error="error"
    :minute-interval="step"
    :format="format"
    :close-on-complete="closeOnComplete"
    :required="required"
    auto-scroll
    hide-disabled-items
    advanced-keyboard
    @input="onInput"
    ref="timePicker"
    :input-class="{
      'time-picker__input': true,
      'time-picker__input--error': error,
      'time-picker__input--disabled': disabled,
    }"
    :hide-clear-button="withoutClearButton"
  >
    <template v-slot:icon v-if="!withoutIcon">
      <i
        class="fal fa-clock"
        :class="{
          'time-picker__icon--disabled': disabled,
        }"
      />
    </template>
    <template v-if="!withoutClearButton" v-slot:clearButton>
      <i class="fal fa-times" />
    </template>
  </vue-timepicker>
</template>

<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

export default {
  components: {
    VueTimepicker,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    format: {
      type: String,
      default: 'h:mm a',
      validator(v) {
        return ['h:mm a', 'h:mma', 'HH:mm'].includes(v);
      },
    },
    value: {
      type: [String, Object],
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Choose time',
    },
    closeOnComplete: {
      type: Boolean,
      default: true,
    },
    step: {
      type: Number,
      default: 15,
      validator(v) {
        return [1, 5, 10, 15, 30, 60].includes(v);
      },
    },
    withoutIcon: Boolean,
    withoutClearButton: Boolean,
    error: Boolean,
    disabled: Boolean,
    required: Boolean,
    forceUpdate: Boolean,
  },
  watch: {
    value(newValue) {
      // if the forceUpdate prop is true, we should update
      // the localValue whenever the value prop changes.
      if (this.forceUpdate) {
        this.localValue = newValue;
      }
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>
<style lang="scss" scoped>
.vue__time-picker {
  width: calc(var(--grid-unit) * 19);
  height: 100%;
  font-family: var(--sans-serif);
  font-variant-numeric: lining-nums;

  &::v-deep .has-custom-icon {
    padding-left: calc(calc(var(--grid-unit) * 2.2) + calc(var(--grid-unit) * 3));
  }

  &::v-deep .clear-btn.has-custom-btn {
    margin: calc(var(--grid-unit) / 2) var(--grid-unit) 0 0;
  }

  &::v-deep .custom-icon {
    top: 3px;
    width: 1em;
    margin: 0 calc(var(--grid-unit) * 1.5);

    .time-picker__icon {
      &--disabled {
        color: var(--gray-20);
      }
    }
  }

  &::v-deep .time-picker__input {
    width: calc(var(--grid-unit) * 19);
    height: calc(var(--grid-unit) * 5);
    font-family: var(--sans-serif);
    font-variant-numeric: lining-nums;
    color: var(--gray-80);
    background: var(--gray-0);
    border: 1px solid var(--gray-80);
    border-radius: var(--radius);

    &:focus {
      outline: none;
      opacity: 1;
    }

    &:focus-within {
      box-shadow: 0 0 0 2px var(--input-focus-color);
    }

    &--error {
      color: var(--rust-50);
      border-color: var(--rust-50);
      box-shadow: 0 0 0 2px var(--rust-0);
    }

    &--disabled {
      color: var(--gray-20);
      border-color: var(--gray-20);
    }
  }

  &::v-deep .is-empty::placeholder {
    color: var(--gray-30);
  }

  &::v-deep .dropdown {
    height: 10.5em;

    .select-list {
      height: 10.5em;
    }
  }

  &::v-deep .dropdown ul li:not([disabled]).active {
    color: var(--viridian-80);
    background: var(--viridian-10);
  }
}
</style>
