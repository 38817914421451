<template>
  <gmap-map
      :center="map.center"
      :zoom="map.zoom"
      class="map search-result-preview__gmap-map"
      :options="map.options"
      v-if="isGoogleLoaded"
    >
    <gmap-marker
      :position="map.center"
      :icon="{
        url: markerIconUrl,
        scaledSize: {width: 32, height: 32}
      }"
    />
  </gmap-map>
</template>

<script>
import mapStyles from '@components/map/map-styles';
import { marker } from '@images';

export default {
  name: 'profile-map',

  props: {
    latitude: {
      type: Number,
      default: 0,
    },
    longitude: {
      type: Number,
      default: 0,
    },
    inNetwork: {
      type: Boolean,
      default: false,
    },
    hasOffers: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    map() {
      return {
        options: {
          draggable: false,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          zoomControl: false,
          cursor: 'arrow',
          styles: mapStyles,
        },
        center: {
          lat: parseFloat(this.latitude),
          lng: parseFloat(this.longitude),
        },
        zoom: 13,
      };
    },

    isGoogleLoaded() {
      return !!window?.env?.GOOGLE_API_KEY;
    },

    markerIconUrl() {
      let type = this.inNetwork ? 'partner' : 'nonPartner';
      if (this.hasOffers) type = 'offer';
      return marker.specific[type].default;
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  height: calc(var(--grid-unit) * 50);
}
</style>
