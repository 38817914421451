<template>
  <div>
    <div class="question-wrapper" v-for="q in centerQuestions" :key="q.id">
      <k-form-field
        v-if="q.choices && q.choices.length > 0"
        :label="questionLabel(q)"
        class="form-field"
        :rules="q.required ? 'required' : ''"
      >
        <div class="hint-text" v-if="q.hint">Hint: {{ q.hint }}</div>
        <k-radio
          v-for="choice in q.choices"
          :key="choice.text"
          :name="'user-input-' + q.id"
          :value="choice.text"
          :modelValue="answers[q.id]?.answer"
          @change="updateAnswer($event, q.id)"
        >
          {{choice.text}}
        </k-radio>
      </k-form-field>
      <k-form-field
        v-else
        :label="questionLabel(q)"
        class="form-field"
        :rules="q.required ? 'required' : ''"
      >
        <div class="hint-text" v-if="q.hint">Hint: {{ q.hint }}</div>
        <k-textarea
          :value="answers[q.id] ? answers[q.id].answer : ''"
          @change="updateAnswer($event, q.id)"
          class="input-width"
        />
      </k-form-field>
    </div>
  </div>
</template>

<script>
import KRadio from '@components/inputs/radio.vue';
import KFormField from '@components/forms/form_field.vue';
import KTextarea from '@components/inputs/textarea.vue';

export default {
  name: 'questions',
  components: {
    KRadio,
    KFormField,
    KTextarea,
  },
  props: {
    waitlist: {
      type: Object,
      default() {
        return {};
      },
    },
    centerQuestions: {
      type: Array,
      required: true,
    },
    bus: {
      type: Object,
      required: false,
    },
    submitStep: {
      type: Function,
      required: false,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      answers: this.waitlist.waitlistData.answers || {},
    };
  },
  created() {
    this.bus?.$on('on-next-step', this.handleNext);

    // If no pre-existing answers, let's build an empty answers object. This is
    // needed so we can use v-model on the input fields.
    if (this.answers.length === 0) {
      const answers = { ...this.answers };
      this.centerQuestions.forEach((q) => {
        answers[q.id] = {
          id: q.id,
          answer: null,
        };
      });
      this.answers = answers;
    }
  },
  beforeDestroy() {
    this.bus?.$off('on-next-step', this.handleNext);
  },

  watch: {
    answers() {
      this.onChange(this.$data);
    },
  },
  methods: {
    handleNext() {
      this.submitStep();
    },
    questionLabel(question) {
      return question.required ? question.question : `${question.question} (optional)`;
    },
    updateAnswer(value, id) {
      const answers = { ...this.answers };
      answers[id] = {
        id,
        answer: value,
      };
      this.answers = answers;
    },
  },
};
</script>
<style lang="scss" scoped>
.question-wrapper {
  margin-bottom: calc(var(--grid-unit) * 3);
}

.hint-text {
  margin-bottom: calc(var(--grid-unit));
  font-size: small;
}

.form-field {
  margin-top: calc(var(--grid-unit));
}

.input-width {
  width: 100%;
}

::v-deep {
  .kFormField {
    &__label {
      margin-bottom: var(--grid-unit);
    }
  }
}
</style>
