<template>
  <div class="app-layout">
    <nav class="app-header" :class="{'app-header--light': light}" v-if="!hideBar">
      <template v-if="userCanEnterApp && showNavigation">
        <router-link
          v-if="hasRouter"
          :to="{ name: 'home' }"
          class="app-header__logo"
          :style="logoStyle"
          aria-label="Kinside"
        >
        </router-link>
        <span
          v-else
          class="app-header__logo"
          aria-label="Kinside"
        >
        </span>
      </template>
      <a
        v-else
        href="/"
        class="app-header__logo app-header__logo--centered"
        :style="logoStyle"
        aria-label="Kinside"
      ></a>

      <user-access can="enterApp" v-if="showNavigation && !hideLinks">
        <section class="app-navigation app-navigation--desktop">
          <slot name="nav"></slot>
        </section>
      </user-access>

      <section class="app-header__top-right" v-if="showNavigation">
        <hubspot />

        <user-access can="useCompleteCare">
          <form :action="completeCareUrbanSitterSsoUrl" method="post">
            <input type="hidden" name="token" :value="ssoAccessToken">
            <input type="hidden" name="refreshToken" :value="ssoRefreshToken">
            <button type="submit" class="urbansitter-button">
              <img src="https://res.cloudinary.com/kinside/image/upload/v1670876122/app_assets/urbansitter_logo_small.svg">
            </button>
          </form>
        </user-access>

        <user-access can="enterApp">
          <bell-notifications>
            <system-button
              :to="{ name: 'notifications' }"
              style-type="clear"
            >
              View all activity
            </system-button>
          </bell-notifications>
          <template #else>
            <system-button
              href="/logout"
              style-type="secondary"
              small
              v-if="userCanEnterApp">Sign out</system-button>
          </template>
        </user-access>

        <a data-method="put" href="/company/managers_view"
           v-if="isManagerUser"
           class="app-header__switch"
           title="Switch to employer mode">
          <i class="fal fa-random"></i>
        </a>

        <component
          :is="userCanEnterApp ? 'router-link' : 'span'"
          class="app-header__avatar"
          :to="{ name: 'settings' }"
          v-if="userCanEnterApp"
        >
          <avatar size="small" :src="avatar" />
        </component>

      </section>
    </nav>
    <div class="app-content">
      <slot></slot>
    </div>
    <nav v-if="showNavigation && showMobileNav">
      <user-access can="enterApp">
        <div class="app-navigation app-navigation--mobile">
          <slot name="nav"></slot>
        </div>
      </user-access>
    </nav>
  </div>
</template>

<script>
import Avatar from '@components/avatar/avatar.vue';
import Hubspot from '@components/hubspot.vue';
import BellNotifications from '@components/bell_notification/bell_notifications.vue';
import SystemButton from '@components/buttons/system-button.vue';
import authMixin from '@profile/mixins/auth';
import { kinsideLogo } from '@images';
import { getEnvVar } from '@utils';

export default {
  name: 'navigation-bar',
  components: {
    Avatar,
    BellNotifications,
    SystemButton,
    Hubspot,
  },
  mixins: [
    authMixin,
  ],
  props: {
    avatar: {
      type: String,
      default: '',
    },
    hideNavigation: {
      type: Boolean,
    },
    light: {
      type: Boolean,
    },
    hideBar: {
      type: Boolean,
      default: false,
    },
    hideLinks: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMobileNav: true,
      showChatWindow: false,
    };
  },
  computed: {
    completeCareUrbanSitterSsoUrl() {
      return getEnvVar('COMPLETE_CARE_URBANSITTER_SSO_URL');
    },
    userCanEnterApp() {
      if (!this.$userAccess) {
        return true;
      }
      return !!this.$store.state.user.current.email && this.$userAccess({ can: 'enterApp' });
    },

    logoStyle() {
      const logo = this.light ? kinsideLogo.dark : kinsideLogo.light;
      return { 'background-image': `url(${logo})` };
    },

    hasRouter() {
      return !!this.$router;
    },

    isManagerUser() {
      return this.$store.state.user.current.isManager;
    },

    ssoAccessToken() {
      return this.$store.state.user.current.ssoAccessToken;
    },

    ssoRefreshToken() {
      return this.$store.state.user.current.ssoRefreshToken;
    },
    showNavigation() {
      return !this.isPreviewMode && !this.hideNavigation;
    },
  },
  mounted() {
    this.toggleMobileNav(this.$route);
  },
  methods: {
    toggleMobileNav(route) {
      this.showMobileNav = route.name !== 'facility-profile';
    },
    toggleChatWindow() {
      this.showChatWindow = !this.showChatWindow;
    },
  },
  watch: {
    $route(to) {
      this.toggleMobileNav(to);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-layout {
  background: var(--viridian-5);
}

.app-header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: var(--z-navigation);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: var(--nav-height);
  background: var(--viridian-50);

  &__logo {
    display: block;
    width: 117.4px;
    height: 25px;
    margin-bottom: calc(var(--grid-unit) * 2);
    margin-left: calc(var(--grid-unit) * 1.5);
    background-repeat: no-repeat;
    background-size: 117.4px 25px;

    @media screen and (min-width: 768px) {
      margin-left: calc(var(--grid-unit) * 2);
    }
  }

  &--light {
    background: var(--viridian-10);
  }

  &__top-right {
    display: flex;
    align-items: flex-end;
    margin-right: calc(var(--grid-unit) * 1.5);
    margin-bottom: calc(var(--grid-unit) * 1.5);

    @media screen and (min-width: 768px) {
      margin-right: calc(var(--grid-unit) * 2);
    }
  }

  &__avatar {
    margin-left: 10px;
  }

  &__switch {
    margin-left: 10px;

    .fa-random {
      color: var(--gold-20);
    }
  }
}

.app-navigation {
  align-items: center;
  height: 100%;

  &--desktop {
    display: none;

    @media screen and (min-width: 768px) {
      display: flex;
    }
  }

  &--mobile {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--z-navigation);
    display: flex;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: center;
    height: var(--mobile-nav-height);
    background: var(--viridian-50);
    padding: 0 calc(var(--grid-unit) * 2);

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}

.app-content {
  min-height: var(--mobile-page-without-nav-height);

  @media screen and (min-width: 768px) {
    min-height: var(--page-without-nav-height);
  }
}

.urbansitter-button {
  display: inline-block;
  padding: 4px 16px;
  margin-right: 20px;
  background-color: #eaf5f6;
  border-width: 0;
  border-radius: 20px;
  transition: background-color var(--transition);

  &:hover {
    background-color: #d6e9ed;
  }

  img {
    width: 86px;
    height: 21px;
  }
}

.app-header__logo--centered {
  margin-left: auto;
  margin-right: auto;
}
</style>
