<template>
  <transition name="notification">
    <section
      v-if="show"
      class="notification"
      :class="{
        'notification--info': info,
        'notification--warning': warning,
        'notification--success': success
      }">
      <div class="notification__content">
        <slot></slot>
      </div>
      <close-button
        class="notification__close-button"
        @click.native="dismiss"
      />
    </section>
  </transition>
</template>

<script>
import CloseButton from './buttons/close-button.vue';

export default {
  name: 'notification',
  components: {
    CloseButton,
  },
  props: {
    info: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    autoDismiss: {
      default: 0,
    },
  },
  data() {
    return {
      show: {
        type: Boolean,
        default: true,
      },
    };
  },
  mounted() {
    if (this.autoDismiss) {
      setTimeout(this.dismiss, parseInt(this.autoDismiss, 10));
    }
  },
  methods: {
    dismiss() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>

  .notification {
    position: fixed;
    top: 80px;
    right: 15px;
    z-index: calc(var(--z-loader) - 1);
    display: flex;
    align-items: flex-start;
    padding: 15px;
    margin-top: 5px;
    margin-left: 15px;
    background: var(--gray-0);
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

    &__close-button {
      position: absolute;
      top: 0;
      right: 0;
    }

    &--info {
      background: var(--teal-0);
    }

    &--success {
      color: var(--gray-0);
      background: var(--viridian-50);
    }

    &--warning {
      background: var(--rust-0);
    }

    &__content {
      padding-right: 25px;

      :last-child {
        margin-bottom: 0;
      }
    }
  }

  .notification-enter-active,
  .notification-leave-active {
    transition: transform 0.5s, opacity 0.5s;
  }

  .notification-enter-to,
  .notification-leave {
    opacity: 1;
    transform: translateY(0);
  }

  .notification-enter,
  .notification-leave-to {
    opacity: 0;
    transform: translateY(100px);
  }
</style>
