<template>
  <span>
    <!-- if more than 3 seats, show expandable/collapsable ui -->
    <span v-if="hasMoreThanThreeSeats">
      <div class="pl-2" v-for="(spot) in firstThreeSeats" :key="spot.id">
        <SeatDetails
          :selectedContractName="spot.contract_name"
          :availableAt="spot.available_date"
          :cost="spot.cost_amount"
          :costFrequency="spot.cost_frequency"
        />
      </div>
      <div class="pt-1" v-if="!shouldShowAllSeats">
        <a href="#" @click.prevent="showAllSpots">
          Show all spots ({{spots.length}}) <span class="fal fa-angle-down"></span>
        </a>
      </div>
      <div v-if="shouldShowAllSeats">
        <div class="pl-2" v-for="(spot) in remainingSeats" :key="spot.id">
          <SeatDetails
            :selectedContractName="spot.contract_name"
            :availableAt="spot.available_date"
            :cost="spot.cost_amount"
            :costFrequency="spot.cost_frequency"
          />
        </div>
        <div class="pt-1">
          <a href="#" @click.prevent="showFewerSpots">
            Show fewer spots <span class="fal fa-angle-up"></span>
          </a>
        </div>
      </div>
    </span>

    <!-- if 3 or fewer, just render those seats -->
    <div class="pl-2" v-for="(spot) in spots" :key="spot.id" v-else>
      <SeatDetails
        :selectedContractName="spot.contract_name"
        :availableAt="spot.available_date"
        :cost="spot.cost_amount"
        :costFrequency="spot.cost_frequency"
      />
    </div>
  </span>
</template>

<script>
import SeatDetails from './seat-details.vue';

export default {
  name: 'expandable-spot-list',
  components: {
    SeatDetails,
  },
  props: {
    spots: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      shouldShowAllSeats: false,
    };
  },
  computed: {
    firstThreeSeats() {
      return this.spots.slice(0, 3);
    },
    remainingSeats() {
      return this.spots.slice(3);
    },
    hasMoreThanThreeSeats() {
      return this.spots.length > 3;
    },
  },
  methods: {
    showAllSpots() {
      this.shouldShowAllSeats = true;
    },
    showFewerSpots() {
      this.shouldShowAllSeats = false;
    },
  },
};
</script>
