/* eslint no-underscore-dangle: ["error", { "allow": ["__store", "__router"] }] */

import { set } from 'lodash';
import { clearEmptyValue } from '@utils';

const parseValue = (value, isArray) => {
  if (value === undefined) {
    return undefined;
  }
  if (isArray) {
    return Array.from(
      new Set(Array.isArray(value) ? value.map((v) => parseValue(v)) : [parseValue(value)]),
    );
  }
  if (!Number.isNaN(Number(value))) {
    return Number(value);
  }
  if (['true', 'false'].includes(value)) {
    return Boolean(value);
  }
  return value;
};

const getValueFromObject = (object, path) => {
  if (!object || !path) {
    return undefined;
  }
  const pathAsArray = path.split('.');
  let currentValue = object;
  for (let i = 0; i < pathAsArray.length; i += 1) {
    currentValue = currentValue[pathAsArray[i]];
    if ([undefined, false].includes(currentValue)
    || (Array.isArray(currentValue) && !currentValue.length)) {
      return undefined;
    }
  }
  return currentValue;
};

const getStoreState = (query, mapping, initialValue) => {
  const state = [];

  mapping.forEach(({
    method, methodName, keys, options,
  }) => {
    keys.forEach((key) => {
      const value = parseValue(query[key.queryName], key.isArray)
        || getValueFromObject(initialValue[methodName], key.storePath)
        || key.default;
      if (value !== undefined) {
        const isObject = keys.length > 1;
        let newState = state.find((item) => item.methodName === methodName);
        if (newState) {
          set(newState.state, key.storePath, value);
        } else if (isObject) {
          newState = initialValue[methodName] ? initialValue[methodName] : {};
          set(newState, key.storePath, value);
          state.push({
            method, methodName, state: newState, options,
          });
        } else {
          state.push({
            method, methodName, state: value, options,
          });
        }
      }
    });
  });

  return state;
};

export default {
  install(Vue, {
    mapping, conditionKey = {}, initialValuesMapping = {}, getSessionBasedQuery,
  }) {
    const getMapping = (condition) => {
      if (typeof mapping === 'function') {
        return mapping(condition);
      }
      return mapping;
    };

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$sync = {
      __router: undefined,
      __store: undefined,

      setup(router, store) {
        this.__router = router;
        this.__store = store;
      },

      async stateWithQuery(query) {
        const state = this.__store?.state;
        if (!Object.keys(query).length || !state) {
          return;
        }

        const initialValue = {};

        if (initialValuesMapping) {
          initialValuesMapping.forEach((i) => {
            initialValue[i.methodName] = {
              [i.key]: { ...getValueFromObject(state, i.path + i.key) },
            };
          });
        }

        const currentMapping = getMapping(query[conditionKey.queryName]);
        const stateFromQuery = getStoreState(query, currentMapping, initialValue);

        this.queryWithState();

        await Promise.all(stateFromQuery.map(({
          method, methodName, state: keyState, options,
        }) => {
          if (options) {
            return this.__store[method](methodName, { ...keyState, ...options });
          }
          return this.__store[method](methodName, keyState);
        }));
      },

      getQueryObject() {
        const state = this.__store?.state;
        if (!this.__router || !state) {
          return undefined;
        }

        const currentMapping = getMapping(getValueFromObject(state, conditionKey.storePath));
        const onlyKeys = currentMapping.reduce(
          (prev, { keys, pathRoot = '' }) => [
            ...prev,
            ...(keys.map(({ storePath, queryName }) => (
              { storePath: pathRoot + storePath, queryName }
            ))),
          ],
          [],
        );

        const query = {};
        onlyKeys.forEach(({ queryName, storePath }) => {
          query[queryName] = getValueFromObject(state, storePath);
        });

        const clearQuery = clearEmptyValue(query, { removeUndefinedFromArray: true });
        return clearQuery;
      },

      queryWithState() {
        const query = getSessionBasedQuery
          ? getSessionBasedQuery(this.getQueryObject())
          : this.getQueryObject();

        const newUrl = new URL(window.location);
        Object.keys(query).forEach((key) => {
          newUrl.searchParams.set(key, query[key]);
        });

        const currentPath = this.__router.currentRoute.path;
        const pathsToPushState = [
          '/childcare/search',
          '/childcare/matches',
          '/childcare/shortlist',
          '/childcare/ignored',
        ];

        // only push state filters to url if on search routes
        if (pathsToPushState.includes(currentPath)) {
          window.history.pushState({}, '', newUrl);
        }
      },
    };
  },
};

const parentInitialsMapping = [{
  methodName: 'search/updateSearch',
  path: 'search.',
  key: 'filters',
}];

const parentMapping = [
  {
    method: 'dispatch',
    methodName: 'search/updateSearch',
    pathRoot: 'search.',
    keys: [
      { storePath: 'filters.budget', queryName: 'budget' },
      {
        storePath: 'filters.philosophies', queryName: 'philosophies', default: [], isArray: true,
      },
      { storePath: 'filters.onlyEmergencyCare', queryName: 'emergencyCare' },
      {
        storePath: 'filters.age.dependents', queryName: 'dep', default: [], isArray: true,
      },
      { storePath: 'filters.startDate.date', queryName: 'startDate' },
      { storePath: 'filters.startDate.value', queryName: 'startOption' },
      { storePath: 'filters.facilityClass', queryName: 'facilityClass' },
      { storePath: 'location.targetAddress', queryName: 'address' },
      { storePath: 'location.state', queryName: 'state' },
      { storePath: 'location.city', queryName: 'city' },
      { storePath: 'location.zip', queryName: 'zip' },
      { storePath: 'location.lat', queryName: 'lat' },
      { storePath: 'location.lon', queryName: 'lon' },
      { storePath: 'location.preciseLocation', queryName: 'precise' },
      { storePath: 'pagination.page', queryName: 'page', default: 1 },
      { storePath: 'searchRadius', queryName: 'radius' },
    ],
  },
  {
    method: 'commit',
    methodName: 'facilities/setMatchesSortOption',
    pathRoot: 'facilities.',
    spread: true,
    keys: [
      { storePath: 'matchesSortOption', queryName: 'matchesSortOption' },
    ],
  },
  {
    method: 'commit',
    methodName: 'facilities/setShortlistSortOption',
    pathRoot: 'facilities.',
    spread: true,
    keys: [
      { storePath: 'shortlistSortOption', queryName: 'shortlistSortOption' },
    ],
  },
  {
    method: 'commit',
    methodName: 'facilities/setSearchSortOption',
    pathRoot: 'facilities.',
    spread: true,
    keys: [
      { storePath: 'searchSortOption', queryName: 'searchSortOption' },
    ],
  },
  {
    method: 'commit',
    methodName: 'map/setGeoSearchType',
    pathRoot: 'map.',
    spread: true,
    keys: [
      { storePath: 'geoSearchType', queryName: 'geoSearchType' },
    ],
  },
  {
    method: 'commit',
    methodName: 'map/updateMapViewport',
    pathRoot: 'map.mapViewport.',
    keys: [
      { storePath: 'bounds.neLat', queryName: 'neLat' },
      { storePath: 'bounds.neLon', queryName: 'neLon' },
      { storePath: 'bounds.swLat', queryName: 'swLat' },
      { storePath: 'bounds.swLon', queryName: 'swLon' },
    ],
  },
  {
    method: 'dispatch',
    methodName: 'map/setBoundsFromURLSync',
    pathRoot: 'map.records.bounds.',
    keys: [
      { storePath: 'neLat', queryName: 'neLat' },
      { storePath: 'neLon', queryName: 'neLon' },
      { storePath: 'swLat', queryName: 'swLat' },
      { storePath: 'swLon', queryName: 'swLon' }],
  },
];

function getParentSessionBasedQuery(queryObject) {
  const {
    page,
    matchesSortOption,
    shortlistSortOption,
    searchSortOption,
    geoSearchType,
    neLat,
    neLon,
    swLat,
    swLon,
  } = queryObject;
  if (geoSearchType === 'viewport_bounds') {
    return {
      page,
      matchesSortOption,
      shortlistSortOption,
      searchSortOption,
      geoSearchType,
      neLat,
      neLon,
      swLat,
      swLon,
    };
  }
  return {
    page,
    matchesSortOption,
    shortlistSortOption,
    searchSortOption,
    geoSearchType,
  };
}

const parentConfig = {
  mapping: parentMapping,
  initialValuesMapping: parentInitialsMapping,
  getSessionBasedQuery: getParentSessionBasedQuery,
};

export { parentConfig };
