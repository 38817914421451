import { Provider } from '../provider/provider';

export default {
  props: {
    provider: {
      type: Provider,
      default: () => ({}),
    },
  },
};
