import { axios, toCamelCase, toSnakeCase } from '@utils';

const BASE_PATH = '/child_care_facilities/';

async function fetchC3RMLeadFormData(facilityId) {
  // const data = {
  //   first_name: 'Ftynh',
  //   last_name: 'Kwon',
  //   email: 'fqiuc7853@tvqrxw.gsm',
  //   phone_number: '4339733688',
  //   source_types: [{ id: 8, label: 'Kinside' }, { id: 7, label: 'Unknown' }],
  //   inquiry_types: [{ id: 3, label: 'Web' }, { id: 7, label: 'Unknown' }],
  //   dependents: [{ first_name: 'iujvapm', last_name: 'K', date_of_birth: '2020-12-01' }],
  // };
  const { data } = await axios.get(
    `${BASE_PATH}${facilityId}/child_care_crm_families/form_data.json`,
  );
  return toCamelCase(data);
}

async function sendC3RMLeadForm(facilityId, formData) {
  const { data } = await axios.post(
    `${BASE_PATH}${facilityId}/child_care_crm_families.json`,
    toSnakeCase(formData),
  );
  return data.family_id;
}

export {
  fetchC3RMLeadFormData,
  sendC3RMLeadForm,
};
