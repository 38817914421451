<template>
  <div class="offers-banner-container mb-3">
    <div>
      <h3 class="offers-header show-front mb-2">
        {{ headerText }}
      </h3>
      <p class="show-front">
        {{ summaryText }}
      </p>
      <router-link
        v-if="!offerAccepted"
        class="mt-2"
        :to="{ name: 'offers', params: { seatId: centerSeatOfferDetail.seatId }}"
      >
        <system-button class="show-front">View spot details</system-button>
      </router-link>
    </div>
  </div>
</template>
<script>
import SystemButton from '@components/buttons/system-button.vue';

export default {
  name: 'center-offers-banner',

  components: {
    SystemButton,
  },

  props: {
    centerSeatOfferDetail: {
      type: Object,
    },
  },

  computed: {
    offerAccepted() {
      return this.centerSeatOfferDetail.offerState === 'enrolled';
    },
    childName() {
      return this.centerSeatOfferDetail.childName;
    },
    headerText() {
      if (this.offerAccepted) return `Offer accepted for ${this.childName}!`;
      return `Spot offer for ${this.childName}!`;
    },
    summaryText() {
      if (this.offerAccepted) {
        return `${this.childName} has a spot at ${this.centerSeatOfferDetail.centerName} starting ${this.centerSeatOfferDetail.startDate}.`;
      }
      return `This provider has offered you a spot in their program, starting ${this.centerSeatOfferDetail.startDate}.`;
    },
  },
};
</script>
<style lang="scss" scoped>
.offers-banner-container {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 204px;
  border-radius: var(--radius-big);

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: url('https://res.cloudinary.com/kinside/image/upload/v1698443170/app_assets/kinside_app/illustration-building-blocks--cropped.png');
    box-shadow: inset 0 0 0 calc(var(--grid-unit) * 125) #fff0f0CC;
  }
}

.offers-header {
  max-width: 100%;
  margin: 0;
}

.show-front {
  position: relative;
  z-index: 1;
}
</style>
