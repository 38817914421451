<script>

export default {
  name: 'user-access',
  props: {
    roleIs: {
      type: [String, Array],
    },
    can: {
      type: [String, Array],
    },
    id: {
      type: String,
    },
    user: { // only for storybook, DO NOT USE
      type: Object,
    },
  },
  computed: {
    userLoaded() {
      return !!this.$store.state.user.current.email;
    },
  },

  render() {
    const userHasAccess = this.$userAccess({ roleIs: this.roleIs, can: this.can, user: this.user });
    return this.userLoaded && userHasAccess ? this.$slots.default : (this.$slots.else || '');
  },
};
</script>
