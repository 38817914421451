import { render, staticRenderFns } from "./legup-waitlist.vue?vue&type=template&id=27a7d918&scoped=true&"
import script from "./legup-waitlist.vue?vue&type=script&lang=js&"
export * from "./legup-waitlist.vue?vue&type=script&lang=js&"
import style0 from "./legup-waitlist.vue?vue&type=style&index=0&id=27a7d918&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27a7d918",
  null
  
)

export default component.exports