import { fetchC3RMLeadFormData, sendC3RMLeadForm } from './c3rm.service';

function getDefaults({ sourceTypes, inquiryTypes, phoneTypes }) {
  const DEFAULT_SOURCE_VALUE = 'kinside';
  const DEFAULT_INQUIRY_VALUE = 'web';
  const DEFAULT_PHONE_VALUE = 'mobile';
  const sourceType = sourceTypes
    .find(({ label }) => label.toLowerCase().includes(DEFAULT_SOURCE_VALUE))?.id;
  const inquiryType = inquiryTypes
    .find(({ label }) => label.toLowerCase().includes(DEFAULT_INQUIRY_VALUE))?.id;
  const phoneType = phoneTypes
    .find(({ label }) => label.toLowerCase().includes(DEFAULT_PHONE_VALUE))?.id;
  return { sourceType, inquiryType, phoneType };
}

export default {
  namespaced: true,
  state: {
    formData: undefined,
    sourceTypes: [],
    inquiryTypes: [],
    phoneTypes: [],
    facilityId: undefined,
    errorMessage: undefined,
  },

  mutations: {
    setTypes(state, { sourceTypes, inquiryTypes, phoneTypes }) {
      state.sourceTypes = sourceTypes;
      state.inquiryTypes = inquiryTypes;
      state.phoneTypes = phoneTypes;
    },
    setFormData(state, payload) {
      state.formData = payload;
    },
    setFacilityId(state, payload) {
      state.facilityId = payload;
    },
    setErrorMessage(state, payload) {
      state.errorMessage = payload;
    },
  },
  actions: {
    async getLeadFormData({
      rootGetters, commit, state,
    }, facilityId) {
      try {
        if (!facilityId) {
          throw new Error();
        }
        if (facilityId === state.facilityId) {
          return;
        }
        const data = await fetchC3RMLeadFormData(facilityId);
        const {
          sourceTypes, inquiryTypes, phoneTypes, dependents, ...parentData
        } = data;
        commit('setTypes', data);
        const typeDefaults = getDefaults({ sourceTypes, inquiryTypes, phoneTypes });
        const dependentsIds = dependents.map(({ firstName, lastName, dateOfBirth }) => {
          const depfromStore = rootGetters['dependents/asArray'];
          return depfromStore.find(
            (d) => d.lastName === lastName
            && d.firstName === firstName
            && d.dateOfBirh === dateOfBirth,
          )?.id;
        }).filter(Boolean);
        commit('setFormData', { parentData, ...typeDefaults, childrenData: dependentsIds });
        commit('setFacilityId', facilityId);
        commit('setErrorMessage', undefined);
      } catch (error) {
        const text = error.response?.data?.message || 'Could not get CCCRM form lead data';
        commit('setErrorMessage', text);
      }
    },
    async sendLeadFormData({
      dispatch, rootState, commit, state,
    }, { childrenData, ...formData }) {
      try {
        if (!state.facilityId) {
          dispatch('notifications/addToastError', { text: 'Could not send lead' }, { root: true });
          return;
        }
        const children = childrenData.map((id) => rootState.dependents.list[id]).filter(Boolean);
        const familyId = await sendC3RMLeadForm(
          state.facilityId,
          { ...formData, childrenData: children },
        );
        commit('setFormData', { childrenData, ...formData });
        dispatch('notifications/addToastNotification', { text: `Form send succesfully, family id: ${familyId}` }, { root: true });
        commit('setErrorMessage', 'This parent has already been sent as a lead');
      } catch (error) {
        dispatch('notifications/addToastError', { text: error.response?.data?.message || 'Could not send CCCRM lead', error }, { root: true });
      }
    },

  },

};
