<template>
  <modal
    :is-open="isOpen"
    @close="closeModal"
  >
    <template #header>
      <div class="header-container">
        <i class="fa-thin fa-credit-card"></i>
        <h6 class="header">Decline Payment Request</h6>
      </div>
    </template>
    <p class="subhead">Please share why you are declining this payment request.</p>
    <k-form-field>
      <k-radio
        v-for="reason in declineReasons()"
        :key="reason.text"
        class="mt-2"
        v-model="selectedReason"
        :value="reason.text"
      >
        {{ reason.text }}
      </k-radio>
    </k-form-field>
    <k-form-field label="Add a message to provider (optional)">
      <k-textarea v-model="message">
      </k-textarea>
    </k-form-field>
    <template #footer>
      <button-container>
        <system-button
          style-type="clear"
          @click="closeModal"
        >
          Cancel
        </system-button>
        <system-button :disabled="selectedReason === null" @click="submitDeclineInfo()">
          Submit
        </system-button>
      </button-container>
    </template>
    <template v-if="loading">
      <loader :loading="loading" />
    </template>
  </modal>
</template>

<script>
import Loader from '@components/loader/loader.vue';
import Modal from '@components/modal/modal.vue';
import ButtonContainer from '@components/layout/button-container.vue';
import SystemButton from '@components/buttons/system-button.vue';
import KFormField from '@components/forms/form_field.vue';
import KRadio from '@components/inputs/radio.vue';
import KTextarea from '@components/inputs/textarea.vue';

export default {
  name: 'decline-form',
  components: {
    Loader,
    Modal,
    ButtonContainer,
    SystemButton,
    KFormField,
    KRadio,
    KTextarea,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    paymentRequest: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedReason: null,
      message: '',
      loading: false,
    };
  },
  methods: {
    declineReasons() {
      return [
        { text: 'I don\'t recognize this provider' },
        { text: 'The amount doesn\'t look right' },
        { text: 'I already paid this request' },
      ];
    },
    async submitDeclineInfo() {
      this.loading = true;
      const params = {
        declineReason: this.selectedReason,
        declineMessage: this.message,
        paymentRequestId: this.paymentRequest.id,
      };
      await this.$store.dispatch('payments/declinePayMeRequest', params);
      await this.$store.dispatch(
        'payments/fetchPayMeRequest',
        {
          id: `${this.$route.query.pr_id}`,
          facilityId: this.paymentRequest.childCareFacilityId,
        },
      );
      this.selectedReason = null;
      this.message = '';
      this.loading = false;
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;
}

.header {
  display: revert;
  color: var(--viridian-5);
  text-align: center;
  margin-left: var(--grid-unit);
}

.subhead {
  font-family: var(--sans);
  font-size: var(--font-size-20);
  margin-bottom: calc(var(--grid-unit) * 2);
}
</style>
