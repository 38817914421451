<template>
  <div>
    <template v-if="this.currentUserId()">
      <template v-if="seatId && !isLegupTourBooked">
        <preview-mode-tooltip v-if="tourEnabled"
          tooltipText="Kinside parents can click here to schedule a tour at your location."
        >
          <system-button
            v-on="!isPreviewMode ? { click: () => openTourModal() } : {}"
            color="periwinkle">
            Book a tour
          </system-button>
        </preview-mode-tooltip>

        <preview-mode-tooltip v-else
          tooltipText="Kinside parents can click here to send you a message."
        >
          <contact-button
            v-if="facility"
            :provider-id="facility.providerId"
            :facility-id="facility.id"
            :email="facility.providerEmail"
            :phone-number="facility.phoneNumber"
            :facility-name="facility.name"
            :button-text="'Message provider'"
            :display-inline="true"
            />
        </preview-mode-tooltip>
      </template>

      <template v-else-if="!seatId && waitlistEnabled">
        <preview-mode-tooltip
          tooltipText="Kinside parents can click here to join your waitlist."
        >
          <system-button
            v-on="!isPreviewMode ? { click: () => openWaitlistModal() } : {}"
            style-type="secondary"
            color="periwinkle">
            Join waitlist
          </system-button>
        </preview-mode-tooltip>
      </template>
    </template>

    <!-- visitor not logged in -->
    <template v-else>
      <template v-if="seatId && tourEnabled">
        <system-button
          color="periwinkle"
          @click="openTourModal()">
          Book a tour
        </system-button>
      </template>

      <template v-else-if="!seatId && waitlistEnabled">
        <system-button
          v-on="{ click: () => openAuthModal('waitlist') }"
          style-type="secondary"
          color="periwinkle"
        >
          Join waitlist
        </system-button>
      </template>
    </template>

    <template v-if="loading">
      <loader :loading="loading" />
    </template>

    <legup-tour
      v-if="tourEnabled && !loading && facility"
      :facilityName="facility.name"
      :facilityTimezone="facility.timezone"
      :facilityPrograms="facility.programs"
      :legupCenterId="facility.legupCenterId"
      :isOpen="tourOpen"
      :seatId="seatId"
      :closeModal="closeTourModal"
      @tour-booked="updateTour"
      @tour-cancelled="onTourCancelled"
    />

    <legup-waitlist
      v-if="waitlistEnabled"
      :provider="provider"
      :isOpen="isWaitlistModalOpen"
      :closeModal="closeWaitlistModal"
      :dependentsWithSpots="dependentsWithSpots"
      :waitlistEnabled="waitlistEnabled"
      :tourBooked="isLegupTourBooked"
      @open-tour-modal="openTourModal"
    />
  </div>
</template>

<script>
import waitlistMixin from '@profile/mixins/waitlist';
import Loader from '@components/loader/loader.vue';
import SystemButton from '@components/buttons/system-button.vue';
import profileComponentMixin from '@profile/mixins/profile-component';
import tourMixin from '@profile/mixins/tour';

import PreviewModeTooltip from '@components/facility-profile/preview-mode-tooltip.vue';

import LegupTour from '@components/facility-profile/legup-tour.vue';
import ContactButton from '@components/facility-profile/contact-button.vue';
import LegupWaitlist from '@components/facility-profile/legup-waitlist.vue';

export default {
  name: 'profile-program-actions',

  mixins: [
    profileComponentMixin,
    waitlistMixin,
    tourMixin,
  ],

  components: {
    SystemButton,
    PreviewModeTooltip,
    LegupTour,
    LegupWaitlist,
    ContactButton,
    Loader,
  },

  props: {
    trackClick: {
      type: Function,
    },
    tourEnabled: {
      type: Boolean,
      default: false,
    },
    seatId: {
      type: Number,
    },
    waitlistEnabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tourOpen: false,
      loading: false,
    };
  },

  methods: {
    currentUserId() {
      return !!this.$store.state.user.current.id;
    },

    openTourModal() {
      if (!this.isPreviewMode) {
        this.tourModal(true);
      }
    },

    closeTourModal() {
      this.tourModal(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.offers {
  margin-bottom: calc(var(--grid-unit) * 6);
}
</style>
