<!-- eslint-disable vue/require-v-for-key -->
<template>
  <span>
    <template v-for="star in rating">
      <span
        v-if="star === 'full'"
        class="profile__star profile__star--full fa-solid fa-star">
      </span>
      <span
        v-else-if="star === 'half'"
        class="profile__star profile__star--half fa-duotone fa-star-half">
      </span>
      <span
        v-else
        class="profile__star profile__star--empty fa-duotone fa-star">
      </span>
    </template>
  </span>
</template>

<script>
export default {
  name: 'profile-stars',

  props: {
    score: {
      type: Number,
      required: true,
    },
  },

  computed: {
    rating() {
      const maxNumberStars = 5;

      const stars = [];
      if (this.score) {
        for (let i = 0; i < Math.floor(this.score); i += 1) {
          stars.push('full');
        }

        if (this.score % 1 !== 0) {
          stars.push('half');
        }

        const emptyStars = maxNumberStars - stars.length;
        for (let i = 0; i < emptyStars; i += 1) {
          stars.push('empty');
        }
      }

      return stars;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
