<template>
  <span>
    <button @click="showReviews"><slot></slot></button>

    <modal
      :is-open="isOpen"
      @close="hideReviews"
      class="modal"
    >
      <div class="title">
        <span class="profile__star profile__star--full fa-solid fa-star"></span>
        {{ averageScore }} - {{ reviews.length }} reviews
      </div>

      <k-input v-model="searchText" placeholder="Search reviews" class="input"></k-input>

      <template v-if="searchText && !filteredReviews.length">
        <div class="search-results">
          There are no results for "{{ searchText }}"
        </div>
        <div class="no">Try searching for something else.</div>
      </template>
      <div
        class="search-results"
        v-else-if="searchText"
      >{{ filteredReviews.length }} reviews mention "{{ searchText }}"</div>

      <div class="profile__review profile__section__border" v-for="review in filteredReviews">
        <div class="profile__review__author">{{ review.author }}</div>
        <div class="profile__review__time">{{ review.time }}</div>
        <div class="profile__review__score" v-if="review.score">
          <stars :score="review.score"></stars>
        </div>
        <div class="profile__review__body" v-html="highlightSearchTerm(review.body)"></div>
      </div>
    </modal>
  </span>
</template>

<script>
import Modal from '@components/modal/modal.vue';
import KInput from '@components/inputs/input.vue';
import Stars from './stars.vue';

export default {
  name: 'profile-button-reviews',

  components: {
    Modal,
    Stars,
    KInput,
  },

  data() {
    return {
      isOpen: false,
      searchText: '',
      minimumSearchLength: 2,
    };
  },

  props: {
    reviews: {
      type: Array,
      required: true,
    },
    averageScore: {
      type: Number,
      required: true,
    },
  },

  computed: {
    filteredReviews() {
      if (!this.searchText || this.searchText.length < this.minimumSearchLength) {
        return this.reviews;
      }

      const reviews = [...this.reviews];
      return reviews.filter(
        (review) => review.body?.toLowerCase().includes(this.searchText.toLowerCase()),
      );
    },
  },

  methods: {
    showReviews() {
      this.isOpen = true;
    },

    hideReviews() {
      this.isOpen = false;
    },

    highlightSearchTerm(text) {
      if (!this.searchText) {
        return text;
      }

      return text.split(' ').map((v) => {
        let newText;

        if (v === this.searchText) {
          newText = `<span class="profile__review__body__highlight">${v}</span>`;
        } else {
          const regex = new RegExp(`(.*)(${this.searchText})(.*)`, 'gi');
          const match = v.search(regex);

          newText = match === 0 ? `<span class="profile__review__body__highlight">${v}</span>` : v;
        }

        return newText;
      }).join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  background: none;
  border: none;
  color: inherit;
  text-decoration: underline;
  padding: 0;
}

.modal {
  text-align: left;
  color: var(--gray-60);
}

.title {
  font-family: var(--serif);
  font-size: var(--font-size-30);
  font-weight: bold;
}

.input {
  width: 100%;
  margin-bottom: calc(var(--grid-unit) * 2);
}

.search-results {
  font-weight: 700;
  margin-bottom: calc(var(--grid-unit) * 2);
  font-size: 16px;
}
</style>
