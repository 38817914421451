<template>
  <div>
    <k-form-field
      :label="subsidizedChildcareLabel"
      class="form-field"
    >
      <k-radio
        v-model="showSubsidies"
        name="showSubsidies"
        :value="false"
        @change="updateShowSubsidy($event)"
      >
        No
      </k-radio>
      <k-radio
        v-model="showSubsidies"
        name="showSubsidies"
        :value="true"
        @change="updateShowSubsidy($event)"
      >
        Yes
      </k-radio>
    </k-form-field>
    <div v-if="showSubsidies">
      <k-checkbox
        v-for="subsidy in centerSubsidies"
        :key="subsidy.subsidy_id"
        v-model="subsidies"
        :value="subsidy.code"
        class="subsidy"
      >
        {{ subsidy.name }}
      </k-checkbox>
    </div>
  </div>
</template>

<script>
import KRadio from '@components/inputs/radio.vue';
import KFormField from '@components/forms/form_field.vue';
import KCheckbox from '@components/inputs/checkbox.vue';

export default {
  name: 'subsidies',
  components: {
    KRadio,
    KFormField,
    KCheckbox,
  },
  props: {
    waitlist: {
      type: Object,
      default() {
        return {};
      },
    },
    bus: {
      type: Object,
      required: true,
    },
    submitStep: {
      type: Function,
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    setLoading: {
      type: Function,
    },
  },
  data() {
    return {
      subsidies: this.waitlist.waitlistData.subsidies || [],
      showSubsidies: this.waitlist.waitlistData.showSubsidies,
      enabledSubsidies: this.waitlist.waitlistData.parentSubsidiesEnabled || [],
    };
  },
  computed: {
    centerSubsidyCodes() {
      // the array of subsidy codes that are saved on the center
      return this.$store.state.legup.centerInfo.subsidies ?? [];
    },
    subsidizedChildcareLabel() {
      return 'Will you have subsidized child care?';
    },
    centerSubsidies() {
      // the actual subsidy records
      return this.$store.state.legup.centerSubsidies;
    },
    parentSubsidiesMatchCenter() {
      // boolean if any matching subsidies
      return this.enabledSubsidies.some((subsidy) => this.centerSubsidyCodes.includes(subsidy));
    },
    centerSubsidiesSharedWithParent() {
      // an array of the codes of the matching subsidies
      return this.centerSubsidies
        .filter((subsidy) => this.enabledSubsidies.includes(subsidy.code))
        .map((subsidy) => subsidy.code);
    },
  },
  async created() {
    this.setLoading(true);
    await this.fetchCenterSubsidies();
    this.matchingSubsidies();
    this.setLoading(false);
    this.bus.$on('on-next-step', this.handleNext);
  },
  beforeDestroy() {
    this.bus.$off('on-next-step', this.handleNext);
  },
  watch: {
    showSubsidies() {
      this.onChange(this.$data);
    },
    subsidies() {
      this.onChange(this.$data);
    },
  },
  methods: {
    async handleNext() {
      await this.submitAddParentSubsidies();
    },
    async fetchCenterSubsidies() {
      await this.$store.dispatch('legup/findCenterSubsidies', { subsidy_codes: this.centerSubsidyCodes });
    },
    matchingSubsidies() {
      const isEnabled = this.parentSubsidiesMatchCenter;
      if (isEnabled) {
        this.showSubsidies = true;
        this.subsidies = this.centerSubsidiesSharedWithParent;
      }
    },
    updateShowSubsidy(value) {
      if (value === true) {
        this.showSubsidies = true;
      } else {
        this.showSubsidies = false;
        this.subsidies = [];
      }
    },
    async submitAddParentSubsidies() {
      this.setLoading(true);
      try {
        await this.$store.dispatch('user/updateSubsidies', { subsidies: this.subsidies });
        this.submitStep();
      } catch (error) {
        this.$store.dispatch('notifications/addToastError', { text: 'Could not create save the subsidies to the parent account. Refresh the page to try again, or contact concierge@kinside.com for more help.', error }, { root: true });
      }
      this.setLoading(false);
    },
  },
};
</script>
<style lang="scss" scoped>

.form-field {
  margin-top: calc(var(--grid-unit));
}

.subsidy {
  margin-left: 24px;
}
</style>
