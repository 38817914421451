/* global Appcues */
export default {
  methods: {
    identifyUserWithAppcues(userIdentifier, userProperties) {
      if (userIdentifier && !this.userIdentifiedWithAppcues) {
        window.AppcuesPromise.then(() => {
          if (window.Appcues) {
            Appcues.identify(userIdentifier, userProperties);
            this.userIdentifiedWithAppcues = true;
          } else {
            // eslint-disable-next-line no-console
            console.error('Appcues is not defined.');
          }
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Appcues load error: ', error);
        });
      }
    },
  },
};
