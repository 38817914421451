<template>
  <opinion-bar-vuex
    :facility-id="facilityId"
  />
</template>

<script>
import OpinionBarVuex from '@components/opinion-bar/opinion-bar-vuex.vue';

export default {
  name: 'profile-opinion-bar',

  props: {
    facilityId: {
      type: Number,
      required: true,
    },
  },

  components: {
    OpinionBarVuex,
  },
};
</script>

<style lang="scss" scoped>
</style>
