<template>
  <div class="profile-mobile-footer-nav">
    <div class="profile-mobile-footer-nav__info">
      <template v-if="!loading">
        <span class="profile-mobile-footer-nav__info__available" v-if="seatsAvailable">
          <span class="fa fa-chair"></span> Open spots!
        </span>
        <template v-else>
          No openings
        </template>
      </template>
    </div>
    <div class="profile-mobile-footer-nav__action">
      <slot></slot>
    </div>
    <div class="profile-mobile-footer-nav__message" v-if="facility">
      <contact-button
        :provider-id="facility.providerId"
        :facility-id="facility.id"
        :email="facility.providerEmail"
        :phone-number="facility.phoneNumber"
        :facility-class="facility.facilityClass"
        :facility-name="facility.name"
        :full-width="true"
        :last-message-date="lastMessageDate"
        :small="true"
      />
    </div>
  </div>
</template>
<script>
import ContactButton from '@components/facility-profile/contact-button.vue';

export default {
  name: 'profile-mobile-footer-nav',

  components: {
    ContactButton,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    facility: {
      type: Object,
      default: () => ({}),
    },
    lastMessageDate: {
      type: String,
      default: '',
    },
    seatsAvailable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-mobile-footer-nav {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  gap: var(--grid-unit);
  background: #fff;
  border-top: 1px solid var(--gray-20);
  padding: calc(var(--grid-unit) * 3) calc(var(--grid-unit) * 2);

  @media screen and (min-width: 768px) {
    display: none;
  }

  &__info {
    flex-grow: 1;
  }

  &__info__available {
    span {
      color: var(--periwinkle-20);
    };
  }

  &__action {
    flex-grow: 1;
  }

  &__message {
    width: 43px;
  }
}
</style>
