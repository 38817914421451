// TODO:  REFACTOR
<template>
  <div class="notifications">
    <button @click="showNotifications"
            class="bell-button"
            v-bind:class="{ 'bell-button--new': unreadItemsCount }"
            type="button"
            id="dropdownNotificationsButton"
            aria-haspopup="true"
            aria-expanded="false">
      <span :class="badgeClass"></span>
      <span v-if="unreadItemsCount"
            class="bell-badge">{{ unreadItemsCount > 99 ? '99+' : unreadItemsCount }}</span>
    </button>

    <div class="notifications-dropdown"
        v-if="isShown"
        aria-labelledby="dropdownNotificationsButton">

      <div v-if="loading"
           class="notification-loading">
        <span class="fas fa-spinner fa-pulse"></span>
      </div>

      <div v-if="!loading"
        class="notification-items">
        <a
          v-for="bellFeedItem in bellFeedItems"
          :key="bellFeedItem.feed_url"
          :href="bellFeedItem.feed_url"
          class="notification-item">
          <span class="notification-item__content" v-html="bellFeedItem.bell_html"></span>
          <span class="notification-item__date">{{ bellFeedItem.notification_date }}</span>
        </a>

        <div v-if="!bellFeedItems.length"
            class="notification-empty">
          No new notifications.
        </div>
      </div>

      <div class="m-b-5">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { axios } from '@utils';

export default {
  name: 'bell-notifications',
  data() {
    return {
      bellFeedItems: [],
      unreadItemsCount: null,
      loading: false,
      isShown: false,
    };
  },

  computed: {
    badgeClass() {
      return 'fas fa-bell';
    },
  },

  mounted() {
    this.loadUnreadCount();
  },

  methods: {
    loadUnreadCount() {
      axios.get(
        '/feed_items/bell_unread_count.json',
      ).then((response) => {
        this.unreadItemsCount = response.data;
        this.$store.dispatch('notifications/setUpdatedStatus', true);
      }).catch((error) => {
        this.$swal({
          type: 'error',
          title: 'Could not get the number of unread notifications',
          text: error,
        });
      });
    },

    // eslint-disable-next-line consistent-return
    loadBellFeedItems() {
      if (this.unreadItemsCount === 0 || this.bellFeedItems.length > 0) {
        return true;
      }
      this.loading = true;
      axios.get(
        '/feed_items/bell_unread.json',
      ).then((response) => {
        this.bellFeedItems = response.data.feed_items;
        this.loading = false;
      }).catch((error) => {
        this.$swal({
          type: 'error',
          title: 'Could not get the number of unread notifications',
          text: error,
        });
        this.loading = false;
      });
    },

    showNotifications() {
      this.isShown = true;
      window.addEventListener('click', this.hideNotifications);
      this.loadBellFeedItems();
    },

    hideNotifications(evt) {
      if (this.$el.contains(evt.target)) return;
      this.isShown = false;
      window.removeEventListener('click', this.hideNotifications);
    },
  },

  watch: {
    '$store.state.notifications.updated': function (newStatus) { // eslint-disable-line func-names
      if (!newStatus) {
        this.loadUnreadCount();
      }
    },
  },

  destroyed() {
    window.removeEventListener('click', this.hideNotifications);
  },
};
</script>

<style lang="scss" scoped>
.m-b-5 {
  margin-bottom: 5px;
}

.notifications {
  position: relative;
}

.notifications-dropdown {
  position: absolute;
  top: 32px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  background: var(--gray-0);
  border: 1px solid var(--gray-5);
  border-radius: var(--radius);
  box-shadow: var(--shadow-20);
}

.bell-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 26px;
  padding: 0 0 5px;
  font-size: 24px;
  color: var(--viridian-5);
  cursor: pointer;
  background: none;
  border: 0;

  &:focus {
    outline: none;
  }

  &--new {
    .fa-bell {
      color: var(--gold-20);
    }
  }
}

.bell-badge {
  position: absolute;
  top: -3px;
  left: 16px;
  min-width: 16px;
  padding: 2px 4px 1px;
  font-size: 12px;
  font-weight: 600;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 13px;
  color: var(--viridian-5);
  text-align: center;
  letter-spacing: -0.2px;
  word-break: initial;
  background-color: var(--coral-20);
  border-radius: 20px;
}

.notification-loading {
  display: block;
  padding: calc(var(--grid-unit) * 2);
  text-align: center;
}

.notification-items {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.notification-empty {
  display: block;
  padding: calc(var(--grid-unit) * 2);
  color: var(--gray-50);
  text-align: center;
}

.notification-item {
  display: block;
  padding: calc(var(--grid-unit) * 2);

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-5);
  }

  &__content {
    // clamp normal text content
    display: -webkit-box;

    // for good measure when it's html
    max-height: calc(var(--grid-unit) * 7);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &--label {
    padding: var(--grid-unit);
    color: var(--gray-50);
    text-align: center;
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: none;
      background: var(--viridian-0);
    }
  }

  &--link-all {
    padding: 7px 10px;
    font-size: 13px;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }

  &__date {
    display: block;
    font-size: 13px;
    font-style: italic;
    color: var(--gray-50);
  }
}
</style>
