<template>
  <div class="form-item">
    <div
      v-if="faIcon"
      class="form-item__icon"
      :class="{
        'form-item__icon--periwinkle': editing || isEmpty
      }"
    >
      <i :class="iconClass"></i>
    </div>
    <div v-if="editing">
      <p class="font-body-large"><slot name="question"></slot></p>
      <slot name="edit"></slot>
    </div>
    <template v-else-if="isEmpty">
      <div class="form-item__label-row">
        <p class="periwinkle-color font-body-large"><slot name="question"></slot></p>
        <system-button
          style-type="clear"
          small
          @click="startEditing"
          fa-icon="pencil"
        >
          change
        </system-button>
      </div>
      <div class="form-item__comment"><slot name="comment"></slot></div>
    </template>
    <template v-else>
      <div class="form-item__label-row">
        <div class="font-body-large"><slot></slot></div>
        <system-button
          style-type="clear"
          small
          @click="startEditing"
          fa-icon="pencil"
        >
          change
        </system-button>
      </div>
      <div class="form-item__comment text-color-50"><slot name="comment"></slot></div>
    </template>
  </div>
</template>

<script>
import SystemButton from '@components/buttons/system-button.vue';

export default {
  components: {
    SystemButton,
  },
  props: {
    faIcon: String,
    isEmpty: Boolean,
    openOnEmpty: Boolean,
  },
  data() {
    return { editing: this.openOnEmpty ? this.isEmpty : false };
  },
  computed: {
    iconClass() {
      return `fal fa-${this.faIcon}`;
    },
  },
  methods: {
    startEditing() {
      this.editing = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-item {
  display: grid;
  grid-template-columns: calc(var(--grid-unit) * 4) 1fr;
  gap: 0 var(--grid-unit);

  & + .form-item {
    margin-top: var(--grid-unit);
  }

  &__icon {
    position: relative;
    height: calc(var(--grid-unit) * 3);
    font-size: 20px;
    color: var(--gray-80);
    text-align: center;

    & .fal {
      position: relative;
    }

    &--periwinkle {
      &::before {
        position: absolute;
        top: 2px;
        left: calc(var(--grid-unit) / 2);
        z-index: 0;
        width: calc(var(--grid-unit) * 3);
        height: calc(var(--grid-unit) * 3);
        content: '';
        background: var(--periwinkle-10);
        border-radius: 50%;
      }
    }
  }

  &__label-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__comment {
    grid-column: 2/3;

    &:empty {
      display: none;
    }
  }

  + .search-item {
    margin-top: var(--grid-unit);
  }

  .periwinkle-color {
    color: var(--periwinkle-50);
  }

  p {
    padding: 0;
    margin-bottom: calc(var(--grid-unit) / 2);
  }
}
</style>
