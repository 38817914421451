<template>
  <div class="offer">
    <div class="offer__descWrapper">
      <div class="offer__title">{{title}}</div>
      <div class="offer__desc">
        {{description}}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.offer {
  display: flex;
  align-items: baseline;

  &__title {
    @extend .font-body-large !optional;

    color: var(--gold-80);

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__desc {
    @extend .font-body-normal !optional;

    color: var(--gold-80);

    &::first-letter {
      text-transform: uppercase;
    }
  }
}
</style>
