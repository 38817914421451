<template>
  <router-link
    :to="to"
    class="navigation-item"
    :class="{'navigation-item--small-padding': smallerPaddingOnTablets}"
  >
    <div class="icon-box">
      <span class="icon" :class="iconClass"></span>
      <span v-if="unreadCount > 0" class="badge">
        {{ unreadCount > 99 ? '99+' : unreadCount }}
      </span>
      <span class="navigation-item__text navigation-item--long"><slot></slot></span>
      <span class="navigation-item__text navigation-item--short">{{short}}</span>
    </div>

  </router-link>
</template>

<script>
export default {
  props: {
    faIcon: {
      type: String,
    },
    to: {
      type: Object,
    },
    short: {
      type: String,
    },
    unreadCount: {
      type: Number,
    },
    smallerPaddingOnTablets: {
      type: Boolean,
    },
  },
  computed: {
    iconClass() {
      const isSolid = this.unreadCount && this.faIcon === 'message' ? 'fa-solid' : 'fal';
      return `${isSolid} fa-${this.faIcon}`;
    },
  },

};
</script>

<style lang="scss" scoped>
@mixin sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.navigation-item {
  height: 100%;
  padding: calc(var(--grid-unit) * 2);
  font-size: var(--font-size-10);
  font-weight: 400;
  color: var(--viridian-0);

  @media screen and (min-width: 768px) {
    margin: 0 calc(var(--grid-unit) / 2);
  }

  &--small-padding {
    @media screen and (max-width: (1200px - 1)) {
      padding-inline: calc(var(--grid-unit));
    }

    @media screen and (max-width: (768px + 30)) {
      padding-inline: 2px;
    }
  }

  &--short {
    margin-left: 10px;

    @media screen and (max-width: 992px) {
      margin-left: 0;
    }

    @media screen and (min-width: 992px) {
      display: none;
    }
  }

  &--long {
    display: none;
    margin-left: 10px;

    @media screen and (min-width: 992px) {
      display: inline-block;
    }
  }

  &.router-link-active {
    color: var(--gold-15);
    background-color: var(--viridian-70);
  }

  .icon-box {
    position:relative;

    .fa-solid {
      color: var(--gold-15);
    }

    .badge {
      position: absolute;
      padding: 0 5px;
      border-radius: 10px;
      top: -4px;
      left: 10px;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: var(--viridian-5);
      background-color: var(--coral-20);
    }
  }
}

.app-navigation--mobile {
  .navigation-item {
    &__text {
      font-size: var(--font-size-0);

      @media screen and (max-width: 324px) {
        @include sr-only;
      }
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 25%;
  }

  .navigation-item--short {
    display: block;
  }
}
</style>
