<template>
  <modal
    :is-open="provider.isEnrollmentModalOpen"
    @close="close"
  >
    <template #header>
      <h6><span class="fal fa-graduation-cap"></span> Log an enrollment</h6>
    </template>
    <k-form>
      <template #header>
        <h4>What are the details of this enrollment?</h4>
        <p class="font-body-large text-color-50">
          If multiple children enrolled, submit one enrollment for each of them.
        </p>
      </template>
      <k-form-item fa-icon="child" isEmpty openOnEmpty>
        <template #question>Which child enrolled?</template>
        <template #edit>
          <dependents-radio-list
          v-model="dependentId"
          :parentId="parentId"/>
       </template>
      </k-form-item>
      <k-form-item fa-icon="calendar" isEmpty openOnEmpty>
        <template #question>What is their start date?</template>
        <template #edit>
          <date-input v-model="startDate"/>
          <k-form-field :label="durationLabel">
            <combo-input>
              <k-input
                v-model.number="expectedDuration"
                :min="0"
              />
              <k-select
                :options="$options.duration"
                v-model="expectedDurationUnit"
                small
              />
            </combo-input>
          </k-form-field>
        </template>
      </k-form-item>
      <k-form-item v-if="showPrograms" fa-icon="graduation-cap" isEmpty openOnEmpty>
        <template #question>Which program are they enrolled in?</template>
        <template #edit>
          <k-select v-model="facilityProgramId" :options="programOptions"/>
        </template>
      </k-form-item>
      <concierge-only-access type="box" class="coa">
        <k-form-item class="only-concierge" isEmpty openOnEmpty>
          <template #question>Close date, pricing, referral fee</template>
          <template #edit>
            <k-form-field label="When did we close this enrollment?">
              <date-input v-model="closeDate" max="today"/>
            </k-form-field>
            <k-form-field label="Was a discount negotiated?">
              <k-radio :value="true" v-model="withDiscount" name="withDiscount">Yes</k-radio>
              <k-radio :value="false" v-model="withDiscount" name="withDiscount">No</k-radio>
            </k-form-field>
            <template v-if="withDiscount === false">
              <k-form-field label="What's the monthly cost?">
                <combo-input>
                  <currency-input v-model="priceCents"/>
                  <k-select
                    :options="$options.price"
                    v-model="priceUnit"
                    small
                  />
                </combo-input>
              </k-form-field>
            </template>
            <template v-else-if="withDiscount">
              <k-form-field label="What’s the monthly cost (before the discount)?">
                <combo-input>
                  <currency-input v-model="priceCents"/>
                  <k-select
                    :options="$options.price"
                    v-model="priceUnit"
                    small
                  />
                </combo-input>
              </k-form-field>
              <k-form-field label="What are the elements of the discount?">
                <k-checkbox value="waived_registration" v-model="discountElements">
                  Waived registration
                </k-checkbox>
                <currency-input
                  v-if="discountElements.includes('waived_registration')"
                  v-model="discounts.waivedRegistration.amount"
                  class="margin-left"
                />
                <k-checkbox value="one_time" v-model="discountElements">
                  One-time discount
                </k-checkbox>
                <currency-input
                  v-if="discountElements.includes('one_time')"
                  v-model="discounts.oneTime.amount"
                  class="margin-left"
                />
                <k-checkbox value="ongoing" v-model="discountElements">
                  Monthly discount
                </k-checkbox>
                <div v-if="discountElements.includes('ongoing')" class="margin-left">
                  <k-form-field label="What's the unit?">
                    <k-radio value="percents" v-model="discounts.ongoing.amountType">
                      % of tuition
                    </k-radio>
                    <k-radio value="dollars" v-model="discounts.ongoing.amountType">
                      Specific $ amount
                    </k-radio>
                    <combo-input>
                      <currency-input
                        v-if="discounts.ongoing.amountType === 'dollars'"
                        v-model="discounts.ongoing.amount"
                      />
                      <k-input
                        v-else-if="discounts.ongoing.amountType === 'percents'"
                        v-model.number="discounts.ongoing.amount"
                        :min="0"
                      />
                      <k-select
                        :options="$options.price"
                        v-model="discounts.ongoing.ongoingUnit"
                        small
                      />
                    </combo-input>
                  </k-form-field>
                  <k-form-field label="How long does the discount last?">
                    <k-radio :value="true" v-model="discounts.ongoing.ongoingIndefinite">
                      Indefinitely
                    </k-radio>
                    <k-radio :value="false" v-model="discounts.ongoing.ongoingIndefinite">
                      A specific # of months
                    </k-radio>
                    <k-input
                      v-if="discounts.ongoing.ongoingIndefinite === false"
                      v-model.number="discounts.ongoing.ongoingDuration"
                      :min="0"
                    >
                      <template #suffix>months</template>
                    </k-input>
                  </k-form-field>
                </div>
              </k-form-field>
            </template>

            <k-form-field label="Is there a referral fee?">
              <k-radio :value="true" v-model="withReferralFee" name="withReferralFee">Yes</k-radio>
              <k-radio :value="false" v-model="withReferralFee" name="withReferralFee">No</k-radio>
            </k-form-field>
            <template v-if="withReferralFee">
              <k-form-field label="Referral Fee Amount">
                <k-radio value="percents" v-model="referralFee.amountType">
                  % of tuition
                </k-radio>
                <k-radio value="dollars" v-model="referralFee.amountType">
                  Specific $ amount
                </k-radio>
                <k-input
                  v-model.number="referralFee.amount"
                  :min="0"
                />
              </k-form-field>
              <k-form-field label="Duration">
                <k-radio value="one_time" v-model="referralFee.commissionType">
                  One Time
                </k-radio>
                <k-radio value="ongoing" v-model="referralFee.commissionType">
                  Ongoing
                </k-radio>
                <k-select
                    v-if="referralFee.commissionType === 'ongoing'"
                    :options="$options.ongoingUnits"
                    v-model="referralFee.ongoingUnit"
                />
                <k-select
                    v-if="referralFee.commissionType === 'ongoing'"
                    :options="ongoingDurations"
                    v-model="referralFee.ongoingDuration"
                />
              </k-form-field>
            </template>

            <k-form-field label="Anything else important to know about this enrollment?">
              <k-textarea v-model="internalNotes"></k-textarea>
            </k-form-field>
          </template>
        </k-form-item>
      </concierge-only-access>
      <loader :loading="sending"/>
    </k-form>

    <template #footer>
      <system-button
        style-type="secondary"
        @click="close">
        Cancel
      </system-button>
      <system-button
        @click="send"
        :disabled="!isValid">
        Submit
      </system-button>
    </template>
  </modal>
</template>

<script>
import Modal from '@components/modal/modal.vue';
import KForm from '@components/forms/form.vue';
import KFormItem from '@components/forms/form-item.vue';
import DependentsRadioList from '@parent/components/dependent-management/dependents-radio-list.vue';
import DateInput from '@components/inputs/date-input.vue';
import KInput from '@components/inputs/input.vue';
import KRadio from '@components/inputs/radio.vue';
import KCheckbox from '@components/inputs/checkbox.vue';
import KSelect from '@components/inputs/k-select.vue';
import KTextarea from '@components/inputs/textarea.vue';
import CurrencyInput from '@components/inputs/currency_input.vue';
import KFormField from '@components/forms/form_field.vue';
import ComboInput from '@components/inputs/combo-input.vue';
import providerProp from '@mixins/providerProp';
import Loader from '@components/loader/loader.vue';
import SystemButton from '@components/buttons/system-button.vue';

export default {
  components: {
    Modal,
    KForm,
    KFormItem,
    DependentsRadioList,
    DateInput,
    KFormField,
    ComboInput,
    KInput,
    KSelect,
    KRadio,
    KCheckbox,
    CurrencyInput,
    KTextarea,
    Loader,
    SystemButton,
  },
  mixins: [providerProp],
  duration: [{ value: 'day', label: 'day(s)' }, { value: 'week', label: 'week(s)' }, { value: 'month', label: 'month(s)' }, { value: 'year', label: 'year(s)' }],
  price: [{ value: 'day', label: 'per day' }, { value: 'week', label: 'per week' }, { value: 'month', label: 'per month' }, { value: 'year', label: 'per year' }],
  ongoingUnits: [{ value: 'day', label: 'every day' }, { value: 'week', label: 'every week' }, { value: 'month', label: 'every month' }, { value: 'year', label: 'every year' }],
  amount: [{ value: 'dollars', label: '$' }, { value: 'percents', label: '%' }],
  data() {
    return {
      sending: false,
      dependentId: undefined,
      startDate: new Date(),
      closeDate: new Date(),
      expectedDuration: undefined,
      expectedDurationUnit: 'month',
      facilityProgramId: undefined,
      withDiscount: undefined,
      withReferralFee: undefined,
      priceCents: undefined,
      priceUnit: 'month',
      internalNotes: '',
      discountElements: [],
      discounts: {
        waivedRegistration: {
          offerType: 'waived_registration',
          amountType: 'dollars',
          amount: undefined,
        },
        oneTime: {
          offerType: 'one_time',
          amountType: 'dollars',
          amount: undefined,
        },
        ongoing: {
          offerType: 'ongoing',
          ongoingUnit: 'month',
          ongoingDuration: undefined,
          ongoingIndefinite: undefined,
          amountType: 'percents',
          amount: undefined,
        },
      },
      referralFee: {
        commissionType: undefined,
        ongoingUnit: undefined,
        ongoingDuration: undefined,
        amountType: undefined,
        amount: undefined,
      },
    };
  },
  computed: {
    durationLabel() {
      return `How many ${this.expectedDurationUnit}s will they be enrolled for?`;
    },
    ongoingDurations() {
      const durations = [{ value: 'indefinite', label: 'indefinitely' }];
      for (let i = 1; i <= 30; i += 1) {
        durations[i] = { value: i, label: `${i} time${i > 1 ? 's' : ''}` };
      }
      return durations;
    },
    showPrograms() {
      return !!this.provider?.currFacility?.programs?.length;
    },
    programOptions() {
      const programs = this.provider?.currFacility?.programs || [];
      return programs.map(({ id, name }) => ({ value: id, label: name }));
    },
    offersAsArray() {
      const { discounts, discountElements } = this;

      return Object.values(discounts)
        .filter((i) => discountElements.includes(i.offerType))
        .map((i) => {
          if (i.amountType === 'dollars') {
            return {
              ...i,
              amount: i.amount ? i.amount / 100 : undefined,
            };
          }
          return i;
        });
    },
    isValid() {
      const {
        facilityProgramId,
        dependentId,
        startDate,
        closeDate,
        expectedDurationUnit,
        priceCents,
        priceUnit,
      } = this;

      const areOffersValid = this.offersAsArray.every((i) => {
        if (i.offerType === 'ongoing' && !i.ongoingIndefinite && !i.ongoingDuration) {
          return false;
        }
        if (i.offerType === 'ongoing' && i.amountType === 'percents' && i.amount > 100) {
          return false;
        }
        return !!i.amount && i.amount > 0;
      });

      return (!this.showPrograms || facilityProgramId) && dependentId && startDate
        && closeDate && expectedDurationUnit && areOffersValid
        && priceCents && priceCents > 0 && priceUnit;
    },
    parentId() {
      const user = this.$store.state.user.current;
      return user?.isParent ? user.id : undefined;
    },
  },
  watch: {
    'provider.isEnrollmentModalOpen': function (n) { // eslint-disable-line func-names
      if (n) {
        this.facilityProgramId = this.provider.programToEnroll;
      }
    },
  },
  methods: {
    close() {
      this.provider.closeEnrollmentModal();
      this.reset();
    },
    reset() {
      this.discountElements = [];
      this.internalNotes = '';
      this.dependentId = undefined;
      this.startDate = new Date();
      this.closeDate = new Date();
      this.expectedDuration = undefined;
      this.withDiscount = undefined;
      this.withReferralFee = undefined;
      this.priceCents = undefined;
      this.discounts.waivedRegistration.amount = undefined;
      this.discounts.ongoing.amount = undefined;
      this.discounts.ongoing.ongoingDuration = undefined;
      this.discounts.oneTime.amount = undefined;
      this.referralFee.amount = undefined;
      this.referralFee.amountType = undefined;
      this.referralFee.ongoingUnit = undefined;
      this.referralFee.ongoingDuration = undefined;
    },
    async send() {
      const {
        facilityProgramId,
        dependentId,
        startDate,
        closeDate,
        expectedDuration,
        expectedDurationUnit,
        priceCents,
        priceUnit,
        internalNotes,
        referralFee,
      } = this;

      if (this.isValid) {
        this.sending = true;
        await this.provider.sendEnrollment({
          facilityOffers: this.offersAsArray,
          facilityProgramId,
          dependentId,
          startDate,
          closeDate,
          expectedDuration,
          expectedDurationUnit,
          priceCents,
          priceUnit,
          internalNotes,
          referralFee,
        });
        this.sending = false;
        this.close();
      } else {
        this.provider.addToastError('Enrollment is not valid');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.coa {
  margin-top: calc(var(--grid-unit) * 3);
}

.only-concierge {
  grid-template-columns: 1fr;
  gap: 0;

  & .margin-left {
    margin-left: 28px;
  }
}
</style>
