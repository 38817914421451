<template>
  <div>
    <div v-if="small" class="contact-button--small">
      <system-button
        v-if="!showContact"
        style-type="secondary"
        @click="reveal"
        :disabled="disableButton"
        class="contact-button__button"
      >
        <span class="fal fa-message"></span>
      </system-button>
    </div>

    <div v-else class="contact-button">
      <p class="tip" v-show="!displayInline">
        {{
          lastMessageDate
            ? `Last message ${displayMessageDate}`
            : 'Want to ask a question?'
        }}
      </p>
      <preview-mode-tooltip
        tooltipText="Kinside parents can click here to send you a message."
      >
        <div class="contact-container"
          :class="{ 'contact-container--inline': displayInline }">
          <system-button
            v-if="!showContact"
            style-type="secondary"
            @click="reveal"
            :full-width="fullWidth"
            :disabled="disableButton"
            :color="buttonColor"
          >
            {{ contactBtnLabel }}
          </system-button>

          <div v-if="showContact">
            <ul class="list">
              <li v-if="phoneNumber">
                <system-button
                  :href="`tel:${phoneNumber}`"
                  style-type="clear"
                  inline
                  fa-icon="phone"
                >
                  {{ phoneNumber }}
                </system-button>
              </li>
            </ul>
          </div>
        </div>
      </preview-mode-tooltip>
    </div>
  </div>
</template>

<script>
import authMixin from '@profile/mixins/auth';
import SystemButton from '../buttons/system-button.vue';
import PreviewModeTooltip from './preview-mode-tooltip.vue';

export default {
  components: {
    SystemButton,
    PreviewModeTooltip,
  },

  mixins: [
    authMixin,
  ],

  props: {
    providerId: Number,
    facilityId: Number,
    phoneNumber: String,
    email: String,
    facilityClass: String,
    fullWidth: Boolean,
    facilityName: String,
    lastMessageDate: String,
    small: {
      type: Boolean,
      default: false,
      required: false,
    },
    buttonText: {
      type: String,
      required: false,
    },
    buttonColor: {
      type: String,
      required: false,
    },
    displayInline: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      showContact: false,
    };
  },

  computed: {
    contactBtnLabel() {
      if (this.buttonText) {
        return this.buttonText;
      }
      return !this.isAuthorized || this.email ? 'Send provider a message' : 'Contact provider';
    },
    displayMessageDate() {
      return this.$moment(this.lastMessageDate).format('MMM Do');
    },
    disableButton() {
      return !this.isAuthorized ? false : !this.email && !this.phoneNumber;
    },
  },

  methods: {
    reveal() {
      if (!this.isAuthorized) {
        this.openAuthModal('contact');
      } else if (this.email) {
        this.$router.push({
          name: 'messaging',
          query: {
            facilityId: this.facilityId,
            providerId: this.providerId,
            facilityName: this.facilityName,
          },
        });
      } else {
        this.showContact = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-button {
  width: 100%;
}

.contact-container {
  width: 100%;
  text-align: center;

  & i:first-child:not(:only-of-type) {
    &::after {
      margin: 0 calc(var(--grid-unit) / 2) 0 var(--grid-unit);
      content: '/';
    }
  }

  & .fal:last-of-type {
    margin-right: var(--grid-unit);
  }

  &--inline {
    display: inline-block;
    width: unset;
  }
}

.list {
  padding-left: 0;
  list-style: none;

  li {
    &:not(:last-child) {
      padding-bottom: var(--grid-unit);
    }
  }
}

.tip {
  font-size: 16px;
  color: var(--gray-60);
  margin-bottom: var(--grid-unit)
}

.contact-button__button {
  width: 43px;
}
</style>
