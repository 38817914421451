<template>
  <aside class="actions">
    <div v-if="showActivity" class="actions__box actions__requests">
      <h4>Your activity</h4>
      <div v-if="provider.enrollments.length"
        class="actions__request-section">
        <enrollment-box
          v-for="en in provider.enrollments"
          :key="en.id"
          :enrollment="en"
        />
      </div>
      <div v-if="provider.tour"
        class="actions__request-section">
        <tour-status
          :unclaimed="unclaimed"
          :reservation='provider.tour'
          :disabled="!facility"
          @schedule-tour='provider.openScheduleTourModal'
        />
      </div>
      <div v-if="provider.requestHistory"
        class="actions__request-section">
        <request-info-card
          :request="provider.requestHistory"
          @open-chat="provider.openChat"
        />
      </div>
    </div>
    <div class="actions__box actions__callout">
      <div v-if="!showActivity" class="actions__callout__header">
        <illustration name="plants" class="actions__callout__header__illustration"/>
      </div>
      <preview-mode-tooltip
        :tooltipText="bookTourTooltipText"
      >
        <system-button
          v-if="!provider.tour"
          fa-icon="calendar"
          color="gold"
          v-on="!isPreviewMode ? { click: bookTour } : {}"
          :disabled="!facility.kinsideTourRequestEnabled"
          full-width
        >
          {{ facility.kinsideTourRequestEnabled ? "Book a tour" : "No tour availability" }}
        </system-button>
      </preview-mode-tooltip>
      <concierge-only-access type="button" full-width>
        <system-button
          fa-icon="graduation-cap"
          v-on="!isPreviewMode ? { click: provider.openEnrollmentModal } : {}"
          full-width
          color="fuchsia"
        >
          Log an enrollment
        </system-button>
      </concierge-only-access>
      <contact-button
        class="contact-button"
        :provider-id="facility.providerId"
        :facility-id="facility.id"
        :email="facility.email"
        :phone-number="facility.phoneNumber"
        :facility-class="facility.facilityClass"
        :facility-name="facility.name"
        :full-width="true"
        :last-message-date="lastMessageDate"
      />
    </div>

    <div
      class="actions__box sticky"
      v-if="provider.showLeadForm">
      <c3rm-lead-form :provider="provider"/>
    </div>

    <schedule-tour-modal
      :is-open="provider.isTourModalOpen"
      :provider="provider"
      :timezone="facility.timezone"
      @close="provider.closeScheduleTourModal"
      @reserve-tour="reserveTour"
    />
    <!-- nothing triggers @reserve-tour  -->

    <profile-mobile-footer-nav
      :facility="this.provider.currFacility"
      :lastMessageDate="lastMessageDate"
    >
      <system-button
        v-if="!provider.tour"
        fa-icon="calendar"
        color="gold"
        v-on="!isPreviewMode ? { click: bookTour } : {}"
        :disabled="!facility.kinsideTourRequestEnabled"
        full-width
      >
        {{ facility.kinsideTourRequestEnabled ? "Book a tour" : "No tour availability" }}
      </system-button>
    </profile-mobile-footer-nav>
  </aside>
</template>

<script>
import providerProp from '@mixins/providerProp';
import Illustration from '@components/illustration.vue';
import authMixin from '@profile/mixins/auth';
import ProfileMobileFooterNav from '@profile/components/profile-mobile-footer-nav';
import SystemButton from '../buttons/system-button.vue';
import TourStatus from './tour-status.vue';
import ScheduleTourModal from './schedule-tour-modal.vue';
import C3rmLeadForm from './c3rm-lead-form.vue';
import EnrollmentBox from './enrollment-box.vue';
import RequestInfoCard from './request-info-card.vue';
import PreviewModeTooltip from './preview-mode-tooltip.vue';
import ContactButton from './contact-button.vue';

export default {
  components: {
    TourStatus,
    SystemButton,
    ScheduleTourModal,
    C3rmLeadForm,
    EnrollmentBox,
    Illustration,
    RequestInfoCard,
    PreviewModeTooltip,
    ContactButton,
    ProfileMobileFooterNav,
  },
  mixins: [providerProp, authMixin],
  beforeMount() {
    if (this.isAuthorized) {
      this.$store.dispatch('user/loadInfoRequestHistory', this.facility.id);
    }
  },
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
    provider: {
      type: Object,
    },
  },
  data() {
    return {
      isTourModalOpen: false,
    };
  },
  computed: {
    facility() {
      return this.provider.currFacility;
    },
    showButton() {
      return !this.tourReservation;
    },
    showReservation() {
      return this.tourReservation;
    },
    unclaimed() {
      return !this.facility.networkLabel;
    },
    showActivity() {
      return !this.isPreviewMode
          && (this.provider.enrollments.length
          || this.provider.requestHistory
          || this.provider.tour);
    },
    isPreviewMode() {
      return this.$store.getters.isPreviewMode;
    },
    eventTrackingProperties() {
      return {
        caregiver_profile_id: this.facility.id,
        caregiver_profile_name: this.facility.name,
        caregiver_profile_network_label: this.facility.networkLabel,
        caregiver_profile_partner_name: this.facility.partnerName,
        caregiver_profile_philosophy: this.facility.philosophy,
        caregiver_profile_url_fragment: this.facility.urlEnding,
        caregiver_profile_button_location: 'action box',
      };
    },
    bookTourTooltipText() {
      if (this.facility.kinsideTourRequestEnabled) {
        return 'This button allows parents to request dates & times to take a tour of your facility.';
      }

      return '';
    },
    lastMessageDate() {
      return this.provider?.lastMessageDate;
    },
  },

  methods: {
    bookTour() {
      if (!this.preview) {
        this.provider.openScheduleTourModal();
      }
    },

    async reserveTour() {
      await this.provider.addToFavorited();
      this.closeScheduleTourModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  height: 100%;
  margin-bottom: calc(var(--grid-unit) * 4);

  @media screen and (min-width: 768px) {
    margin-bottom: calc(var(--grid-unit) * 6);
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: calc(var(--grid-unit) * 3);
    margin-bottom: calc(var(--grid-unit) * 2);
    border-radius: var(--radius-big);

    @media screen and (min-width: 768px) {
      margin-bottom: calc(var(--grid-unit) * 3);

      &.sticky {
        position: sticky;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    & h4 {
      margin-bottom: calc(var(--grid-unit) * 3);
    }
  }

  &__requests {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: calc(var(--grid-unit) * 3);
    border: var(--border) var(--gray-30);
  }

  &__request-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: calc(var(--grid-unit) * 2);
  }

  &__callout {
    padding: calc(var(--grid-unit) * 3);
    text-align: center;
    border: var(--border) var(--gray-30);

    > *:not(:last-child) {
      margin-bottom: calc(var(--grid-unit) * 2);
    }

    &__header {
      &__illustration {
        width: 160px;
        margin: 0 auto calc(var(--grid-unit) * 2) auto;
      }
    }

    @media screen and (min-width: 768px) {
      position: sticky;
      top: calc(var(--nav-height) + calc(var(--grid-unit) * 3));
    }
  }
}

.contact-button {
  width: 100%;
}

</style>
