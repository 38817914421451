<template>
  <div v-if="isAuthorized">
    <div class="hubspot-chat__container" :style="{ display: showChatWindow ? 'block' : 'none' }">
      <div id="hubspot-chat-widget"></div>
      <button class="hubspot-chat__button hubspot-chat__button--close"
        @click="toggleChatWindow"
        aria-haspopup="false"
        aria-expanded="false">
        <i class="fal fa-xmark"></i>
      </button>
    </div>
    <button class="hubspot-chat__button"
      @click="toggleChatWindow"
      aria-haspopup="true"
      aria-expanded="false">
      <i class="fal fa-circle-question"></i>
    </button>
  </div>
</template>

<script>
import authMixin from '@profile/mixins/auth';

export default {
  name: 'hubspot',

  mixins: [
    authMixin,
  ],

  data() {
    return {
      showChatWindow: false,
    };
  },

  methods: {
    toggleChatWindow() {
      this.showChatWindow = !this.showChatWindow;
    },
  },
};
</script>

<style lang="scss" scoped>
.hubspot-chat__button {
  width: 28px;
  height: 28px;
  padding: 0;
  font-size: 24px;
  color: var(--viridian-5);
  cursor: pointer;
  background: none;
  border: 0;
  margin-right: var(--grid-unit);

  &--close {
    position: absolute;
    top: 60px;
    right: -5px;
    background-color: var(--viridian-50);
    border-radius: 100%;
    height: 42px;
    width: 42px;
    transition: background-color var(--button-fade);

    &:hover {
      background-color: var(--viridian-60);
    }
  }
}
</style>
