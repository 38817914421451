<template>
  <div class="seat-details">
    <span class="fal fa-chair pr-1" />
    <span class="seat-details__text">
      {{ [selectedContractName, startingAt, formattedCost].filter(Boolean).join(' • ') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'seat-details',
  props: {
    selectedContractName: {
      type: String,
      default: null,
    },
    availableAt: {
      type: String,
      required: true,
    },
    cost: {
      type: Number,
      required: true,
    },
    costFrequency: {
      type: String,
      required: true,
    },
  },
  methods: {
    formattedDate(dateString) {
      return this.$moment(dateString).utc().format('MMMM D, YYYY');
    },
  },
  computed: {
    startingAt() {
      return this.availableAt ? `starting ${this.formattedDate(this.availableAt)}` : '';
    },
    formattedCost() {
      return `$${this.cost / 100} / ${this.costFrequency}`;
    },
  },
};
</script>

<style scoped>
.seat-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--grid-unit);
}

.seat-details__text {
  line-height: 1.2;
}
</style>
