<template>
  <div class="mb-3">
    <box :big-radius="true" color="periwinkle-light" class="legup-tour-booked">
      <h3 class="font-heading-20">Tour booked</h3>
      <box fa-icon="calendar" :no-icon-bg="true" color="periwinkle" class="small-box">
        {{ tour.startTime | moment('timezone', timezone, 'MMM D, YYYY h:mm A') }} {{ timeZoneAbbr }}
      </box>
      <p>Need to reschedule?</p>
      <a href="#" @click.prevent="editTour()">
        <span class="link-periwinkle">Make changes to day & time</span>
      </a>
    </box>
  </div>
</template>

<script>
import Box from '@components/box.vue';
import timeZoneAbbr from '../../../common/mixins/timeZoneAbbr';

export default {
  components: {
    Box,
  },
  props: {
    tour: Object,
  },
  mixins: [timeZoneAbbr],
  methods: {
    editTour() {
      this.$emit('edit-tour');
    },
  },
};
</script>

<style lang="scss" scoped>
.legup-tour-booked {
  color: var(--gray-80);
  text-align: center;
}

.link-periwinkle {
  color: var(--periwinkle-50);
}

.small-box {
  justify-content: center;
  padding: calc(var(--grid-unit) * 0.5);
  margin-bottom: calc(var(--grid-unit) * 2);
}
</style>
