import { getStringFromArray } from '@utils';
import UserAccess from './user-access.vue';
import ConciergeOnlyAccess from './concierge-only-access.vue';

function getArray(value) {
  if (value === undefined) {
    return undefined;
  }
  return Array.isArray(value) ? value : [value];
}

function validateArray(value, validValues) {
  return value.every((v) => validValues.includes(v));
}

function checkAccess(required, userAccess) {
  if (required === undefined) {
    return true;
  }
  return required.map((r) => userAccess[r]).some(Boolean);
}

function getUserRoles(user = {}) {
  return {
    impersonated: !!user.isImpersonated,
    admin: !!user.isAdmin,
    parent: !user.isImpersonated && !!user.isParent,
    provider: !user.isImpersonated && !!user.isProvider,
    manager: !user.isImpersonated && !!user.isManager,
    concierge: !!user.isConcierge,
  };
}

function getUserPermissions(user = {}, userRoles = {}) {
  const doPayments = (() => {
    if (userRoles.parent) {
      return user.parentPaymentsEnabled;
    }
    if (userRoles.provider) {
      return user.paymentsAvailable;
    }
    if (userRoles.impersonated) {
      return user.parentPaymentsEnabled || user.paymentsAvailable;
    }
    return false;
  })();

  const doKinsideFeatures = (() => !(userRoles.parent || userRoles.impersonated)
    || !user.paymentCollection || user.parentPaymentOnboarding)();

  return {
    doPayments,
    doKinsideFeatures,
    useNavia: (userRoles.impersonated || userRoles.parent) && !!user.naviaParent,
    useCompleteCare: (
      user.confirmedAt
      && (userRoles.impersonated || userRoles.parent)
    ) ? user.completeCare : false,
    enterConfirmAccount: userRoles.parent ? !!user.parentEnterConfirmAccount : false,
    enterOnboarding: (userRoles.parent && user.confirmedAt) || userRoles.provider
      ? !user.onboardingCompleted && !user.paymentOnboardingStarted
      && !user.paymentOnboardingCompleted : false,
    enterPaymentOnboarding: userRoles.provider
      ? user.paymentOnboardingStarted && !user.paymentOnboardingCompleted : false,
    parentEnterPaymentOnboarding: userRoles.parent
      ? user.paymentCollection && !user.parentPaymentOnboarding : false,
    enterApp: userRoles.impersonated
      || (userRoles.parent ? !!user.parentEnterApp : userRoles.manager || !!user.confirmedAt),
    enterPassword: userRoles.manager && !user.passwordSet,
    sendC3rmLead: userRoles.impersonated,
  };
}

const ROLES = ['admin', 'parent', 'provider', 'manager', 'impersonated', 'concierge'];
const PERMISSIONS = ['doPayments', 'doKinsideFeatures', 'useNavia', 'useCompleteCare', 'enterOnboarding', 'enterConfirmAccount', 'enterPaymentOnboarding', 'enterApp', 'enterPassword', 'sendC3rmLead', 'parentEnterPaymentOnboarding'];

export default {
  install(Vue) {
    const uA = {
      roles: getUserRoles(),
      permissions: getUserPermissions(),
    };

    Vue.component('user-access', UserAccess);
    Vue.component('concierge-only-access', ConciergeOnlyAccess);

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$setupUserAccess = (user) => {
      uA.roles = getUserRoles(user);
      uA.permissions = getUserPermissions(user, uA.roles);
    };

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$userAccess = ({ roleIs, can, user }) => {
      if (user) {
        Vue.prototype.$setupUserAccess(user);
      }

      const [rolesArray, permissionsArray] = [getArray(roleIs), getArray(can)];

      if (roleIs && !validateArray(rolesArray, ROLES)) {
        // eslint-disable-next-line no-console
        console.warn('Please, check roles: ', getStringFromArray(rolesArray));
        return false;
      }

      if (can && !validateArray(permissionsArray, PERMISSIONS)) {
        // eslint-disable-next-line no-console
        console.warn('Please, check permissions: ', getStringFromArray(permissionsArray));
        return false;
      }

      return checkAccess(rolesArray, uA.roles) && checkAccess(permissionsArray, uA.permissions);
    };
  },
};

export { PERMISSIONS, ROLES, UserAccess };
