<template>
  <div>
    <illustration name="toys" class="waitlist-warning--blocks"/>
    <h3 class="waitlist-warning--header">Join the waitlist</h3>
    <h5 class="waitlist-warning--subhead font-body-large">We'll hold your spot in line.</h5>
    <div class="waitlist-warning--anchor-container">
      <a href="https://knowledge.kinside.com/how-do-i-find-where-i-am-on-a-child-care-providers-waitlist" target="_blank"><h5 class="waitlist-warning--anchor-text font-body-large">How do waitlists work on Kinside?</h5></a>
    </div>
    <div v-if="totalFees" class="waitlist-warning--fee-notice">
      <span class="fa-light fa-circle-dollar waitlist-warning--icon"></span>
      <p class="waitlist-warning--notice-text">
        Register for this provider’s waitlist on Kinside by paying a one-time, non-refundable fee
        of {{formattedTotalFees}}. Additional charges apply for credit card transactions,
        while bank transfers are free of charge.
      </p>
    </div>
  </div>
</template>

<script>
import Illustration from '@components/illustration.vue';
import { getDollarFormatNoZeros } from '@utils';

export default {
  components: {
    Illustration,
  },

  props: {
    waitlist: {
      type: Object,
      default() {
        return {};
      },
    },
    bus: {
      type: Object,
      required: true,
    },
    submitStep: {
      type: Function,
      required: true,
    },
  },

  created() {
    this.bus.$on('on-next-step', this.handleNext);
  },

  beforeDestroy() {
    this.bus.$off('on-next-step', this.handleNext);
  },

  computed: {
    totalFees() {
      return this.$store.state.legup.waitlistFees || 0;
    },
    formattedTotalFees() {
      return getDollarFormatNoZeros(this.totalFees);
    },
  },

  methods: {
    handleNext() {
      this.submitStep();
    },
  },
};
</script>

<style lang="scss" scoped>
  .waitlist-warning {
    &--blocks {
      max-width: 80px;
      margin: auto;
    }

    &--header {
      padding: 30px 0;
      font-size: 40px;
      font-weight: normal;
      text-align: center;
    }

    &--subhead {
      max-width: 100%;
      font-weight: normal;
      text-align: center;
    }

    &--anchor-container {
      padding: 20px 0;
      text-align: center;
    }

    &--anchor-text {
      max-width: 100%;
      font-weight: normal;
      color: var(--viridian-50);
      text-decoration: underline;
    }

    &--fee-notice {
      display: flex;
      justify-content: center;
      padding: 20px;
      color: #72400b;
      background-color: #fff9e6;
      border: 1px solid #bf842c;
      border-radius: 8px;
    }

    &--icon {
      padding-right: 10px;
      font-size: 18px;
      line-height: unset;
      color: #72400b;
    }

    &--notice-text {
      margin: 0;
    }
  }
</style>
