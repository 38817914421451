<template>
  <k-form class="c3rm-form">
    <template v-slot:header>
      <h4>
        CCCRM Lead Form
        <span v-if="loading" class="fas fa-spinner fa-pulse"></span>
      </h4>
    </template>
    <p v-if="provider.c3rmErrorMessage">
      {{provider.c3rmErrorMessage}}
    </p>
    <validation-observer v-else v-slot="{ valid }">
      <k-fieldset legend="Parent data">
        <k-form-field label="First name">
          <k-input
            v-model.trim="formData.parentData.firstName"
            rules="required"
          ></k-input>
        </k-form-field>
        <k-form-field label="Last name">
          <k-input
            v-model.trim="formData.parentData.lastName"
            rules="required"
          ></k-input>
        </k-form-field>
        <k-form-field label="Email">
          <k-input
            v-model.trim="formData.parentData.email"
            type="email"
            rules="required|email"
          >
          </k-input>
        </k-form-field>
        <k-form-field label="Phone number">
          <k-input
            v-model.trim="formData.parentData.phoneNumber"
            type="tel"
            :rules="{regex: mobileRegex, required: true}"
          >
          </k-input>
        </k-form-field>
        <k-form-field label="Phone type">
          <k-select
            v-model.trim="formData.phoneType"
            :options="provider.phoneTypeOptions"
          >
          </k-select>
        </k-form-field>
        <k-form-field label="Dependents">
          <dependents-checkbox-list v-model="formData.childrenData"/>
        </k-form-field>
      </k-fieldset>
      <k-fieldset legend="Lead data">
        <k-form-field label="Source type">
          <k-select
            v-model.trim="formData.sourceType"
            :options="provider.sourceOptions"
          >
          </k-select>
        </k-form-field>
        <k-form-field label="Inquiry type">
          <k-select
            v-model.trim="formData.inquiryType"
            :options="provider.inquiryOptions"
          >
          </k-select>
        </k-form-field>
      </k-fieldset>
      <button-container>
        <system-button :disabled="!valid || !leadValid || loading" @click="sendData">
          Send
        </system-button>
      </button-container>
    </validation-observer>
  </k-form>
</template>

<script>
import KForm from '@components/forms/form.vue';
import KFormField from '@components/forms/form_field.vue';
import KFieldset from '@components/forms/fieldset.vue';
import KInput from '@components/inputs/input.vue';
import KSelect from '@components/inputs/k-select.vue';
import DependentsCheckboxList from '@parent/components/dependent-management/dependents-checkbox-list.vue';
import providerProp from '@mixins/providerProp';
import { ValidationObserver } from 'vee-validate/dist/vee-validate.full';
import ButtonContainer from '@components/layout/button-container.vue';
import { regexPatterns } from '@utils';
import SystemButton from '../buttons/system-button.vue';

export default {
  components: {
    KSelect,
    KInput,
    KFormField,
    KForm,
    DependentsCheckboxList,
    KFieldset,
    SystemButton,
    ValidationObserver,
    ButtonContainer,
  },

  mixins: [providerProp],

  data() {
    return {
      formData: { parentData: {}, ...this.provider.formData },
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    const formData = await this.provider.getLeadFormData();
    this.formData = { parentData: {}, childrenData: [], ...formData };
    this.loading = false;
  },
  computed: {
    leadValid() {
      const { childrenData, inquiryType, sourceType } = this.formData;
      return childrenData?.length && inquiryType && sourceType;
    },
    mobileRegex() {
      return regexPatterns.mobileNumber.regex;
    },
  },
  methods: {
    sendData() {
      this.$store.dispatch('c3rm/sendLeadFormData', this.formData);
    },
  },
};
</script>

<style lang="scss" scoped>
.c3rm-form.c3rm-form {
  padding: calc(var(--grid-unit) * 4);
  text-align: left;
  background: var(--fuchsia-0);
  border: var(--border) var(--fuchsia-50);
}
</style>
