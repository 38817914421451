<template>
  <div>
    <vue-gallery
      :images="images"
      :index="profileImageIndex"
      @close="closeGallery"
      :id="`gallery-${mobile ? 'mobile' : 'desktop'}`"
      class="gallery__modal"
    >
    </vue-gallery>

    <div class="gallery">
      <a class="back" href="/" v-if="isAuthorized">
        <span class="fa fa-arrow-left"></span>
      </a>
      <div
        class="gallery__images"
        :class="[`gallery__images--grid-${ displayImages.length }`]"
      >
        <div
          v-for="(image, index) in displayImages"
          :key="index"
          :style='{ backgroundImage: `url("${image}")` }'
          class="gallery__image"
          @click="openGallery(index)"
          ></div>
      </div>

      <button
        v-if="images.length > maxBannerPhotos"
        @click="openGallery(0)"
        class="gallery__all profile__button profile__button--secondary"
      >
        <span class="fal fa-image"></span> View all images
      </button>
    </div>
  </div>
</template>

<script>
import VueGallery from 'vue-gallery';
import AuthMixin from '@profile/mixins/auth';

export default {
  name: 'profile-gallery',

  components: {
    VueGallery,
  },

  mixins: [
    AuthMixin,
  ],

  props: {
    images: {
      type: Array,
      required: true,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      profileImageIndex: null,
      maxBannerPhotos: 5,
    };
  },

  methods: {
    openGallery(index) {
      this.profileImageIndex = index;
    },

    closeGallery() {
      this.profileImageIndex = null;
    },
  },

  computed: {
    displayImages() {
      return this.images.slice(0, this.maxBannerPhotos);
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  position: relative;
  margin-bottom: calc(var(--grid-unit) * 2);

  @media screen and (min-width: 768px) {
    margin-bottom: calc(var(--grid-unit) * 5);
  }
}

.gallery__image {
  height: 100%;
  background: var(--gray-5);
  background-position: center;
  background-size: cover;
  cursor: pointer;

  &:first-child {
    grid-row: 1/3;
    grid-column: 1 / 4;
  }

  &:only-child {
    grid-column: 1 / 6;
  }
}

.gallery__images {
  overflow: hidden;
  height: 200px;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: calc(var(--grid-unit) / 2);
    border-radius: 16px;
    height: 280px;
  }

  .gallery__image:not(:first-child) {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

.gallery__images--grid-5 {
  .gallery__image:nth-child(2) {
    grid-row: 1/2;
    grid-column: 4/5;
  }

  .gallery__image:nth-child(3) {
    grid-row: 1/2;
    grid-column: 5/6;
  }

  .gallery__image:nth-child(4) {
    grid-row: 2/3;
    grid-column: 4/5;
  }

  .gallery__image:nth-child(5) {
    grid-row: 2/3;
    grid-column: 5/6;
  }
}

.gallery__images--grid-4 {
  .gallery__image:nth-child(2) {
    grid-row: 1/2;
    grid-column: 4/6;
  }

  .gallery__image:nth-child(3) {
    grid-row: 2/3;
    grid-column: 4/5;
  }

  .gallery__image:nth-child(4) {
    grid-row: 2/3;
    grid-column: 5/6;
  }
}

.gallery__images--grid-3 {
  .gallery__image:nth-child(2) {
    grid-row: 1/2;
    grid-column: 4/6;
  }

  .gallery__image:nth-child(3) {
    grid-row: 2/3;
    grid-column: 4/6;
  }
}

.gallery__images--grid-2 {
  grid-template-columns: 1fr 1fr;

  .gallery__image:nth-child(2) {
    grid-row: 1/3;
    grid-column: 2/2;
  }

  .gallery__image:nth-child(1) {
    grid-column: 1/2;
  }
}

.gallery__all {
  right: 20px;
  bottom: 10px;
  position: absolute;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.back {
  position: absolute;
  top: calc(var(--grid-unit) * 2);
  left: calc(var(--grid-unit) * 2);
  width: calc(var(--grid-unit) * 5);
  height: calc(var(--grid-unit) * 5);
  line-height: calc(var(--grid-unit) * 5);
  border-radius: 100%;
  text-align: center;
  background: #fff;
  border: 1px solid var(--viridian-50);
  color: var(--viridian-50);

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.gallery__modal {
  color: #fff;
}
</style>
