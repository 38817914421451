<template>
  <box color="viridian" class="request-card">
    <div class="request-card__header">
      <i class="fal fa-question-circle"/><span>You asked a question</span>
    </div>
    <div class="font-body-small request-card__date">{{date}}</div>
    <div>
      If you don’t receive a response within 2-3 business days,
      <system-button inline style-type="clear" @click="openChat">let us know</system-button>.
    </div>
  </box>
</template>

<script>
import Box from '@components/box.vue';
import SystemButton from '@components/buttons/system-button.vue';

export default {
  components: { Box, SystemButton },
  props: {
    request: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    date() {
      return this.$moment(this.request).fromNow();
    },
  },
  methods: {
    openChat() {
      this.$emit('open-chat');
    },
  },
};
</script>

<style lang="scss" scoped>
.request-card {
  text-align: left;

  &__header {
    display: flex;
    line-height: calc(var(--grid-unit) * 3);

    & .fal {
      display: inline-block;
      margin-right: var(--grid-unit);
    }
  }

  &__date {
    margin: var(--grid-unit) 0;
  }
}

</style>
