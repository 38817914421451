<template>
  <div class="container">
    <h3 class="warning--header">Wait a second!</h3>
    <p class="p-s-24">
      This provider requires that you take a tour before you can join their waitlist.
    </p>
  </div>
</template>

<script>

export default {
  name: 'tour-required',
  props: {
    bus: {
      type: Object,
      required: true,
    },
    submitStep: {
      type: Function,
      required: true,
    },
  },

  created() {
    this.bus.$on('on-next-step', this.handleNext);
  },

  beforeDestroy() {
    this.bus.$off('on-next-step', this.handleNext);
  },

  methods: {
    async handleNext() {
      this.submitStep();
    },
  },
};
</script>

<style scoped>
.container {
  text-align: center;
  padding-bottom: 0;
  padding-top: 4rem;
}

.p-s-24 {
  padding: 16px 24px 0;
}

.warning {
  &--blocks {
    max-width: 80px;
    margin: auto;
  }

  &--header {
    padding: 30px 0;
    font-size: 40px;
    font-weight: normal;
    text-align: center;
  }
  }
</style>
