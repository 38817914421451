<template>
  <special-info-box v-if="offers.length" class="offers">
    <template #tag>Members only offers</template>
    <offers-list :offers="offers" />
  </special-info-box>
</template>

<script>
import profileComponentMixin from '@profile/mixins/profile-component';
import SpecialInfoBox from '@components/facility-profile/special-info-box.vue';
import OffersList from '@components/facility-profile/offers/offers-list.vue';

export default {
  name: 'profile-offers',

  mixins: [
    profileComponentMixin,
  ],

  components: {
    OffersList,
    SpecialInfoBox,
  },

  computed: {
    offers() {
      if (!this.facility) {
        return [];
      }

      if (this.facility.skipNetworkDiscount) {
        return [...this.facility.offers || []];
      }

      return [...this.facility.offers || [], ...this.facility.networkOffers || []];
    },
  },
};
</script>

<style lang="scss" scoped>
.offers {
  margin-bottom: calc(var(--grid-unit) * 6);
}
</style>
