var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"legup-actions"},[_c('box',{attrs:{"color":"white","big-radius":true}},[_c('div',{staticClass:"legup-actions__content"},[[_c('img',{staticClass:"legup-actions__center-logo",attrs:{"src":_vm.providerLogo,"alt":"Provider logo","height":"108"}})],_vm._v(" "),(_vm.seatsAvailable)?[_c('p',{staticClass:"font-body-large open-spots"},[_vm._v("This provider has open spots!")])]:_vm._e(),_vm._v(" "),(_vm.tourEnabled)?[_c('preview-mode-tooltip',{attrs:{"tooltipText":"Kinside parents can click here to schedule a tour at your location."}},[(_vm.isLegupTourBooked)?_c('box',{staticClass:"mb-4 mark-done",attrs:{"no-icon-bg":true,"faIcon":"check-circle"}},[_vm._v("\n            Tour booked\n          ")]):[_c('system-button',_vm._g({staticClass:"mb-4 tour-button",attrs:{"fa-icon":"calendar","target":"_blank","full-width":""}},!this.currentUserId() ? { click: () => _vm.openAuthModal('tour') } :
              !_vm.isPreviewMode ? { click: () => _vm.openTourModal() }  :  {}),[_c('div',{staticClass:"legup-actions__button-content"},[_vm._v("\n                Book a tour\n              ")])])],_vm._v(" "),(!_vm.loading && _vm.facility)?_c('legup-tour',{attrs:{"facilityName":_vm.facility.name,"facilityTimezone":_vm.facility.timezone,"facilityPrograms":_vm.facility.programs,"legupCenterId":_vm.facility.legupCenterId,"isOpen":_vm.isTourModalOpen,"edit":_vm.editTour,"bookedTour":_vm.upcomingBookedTour,"closeModal":_vm.closeTourModal},on:{"tour-booked":_vm.tourBooked,"tour-cancelled":_vm.onTourCancelled}}):_vm._e()],2)]:_vm._e(),_vm._v(" "),(_vm.isWaitlistEnabled)?[_c('p',{staticClass:"tip"},[_vm._v("\n          Not ready to enroll yet?\n        ")]),_vm._v(" "),_c('preview-mode-tooltip',{attrs:{"tooltipText":"Kinside parents can click here to join your waitlist."}},[_c('system-button',_vm._g({staticClass:"mb-2 legup-actions__button",attrs:{"style-type":"secondary","target":"_blank","full-width":"","disabled":_vm.disableButton}},!this.currentUserId()
              ? { click: () => _vm.openAuthModal('waitlist') } : !_vm.isPreviewMode
              ? { click: () => _vm.openWaitlistModal() } : {}),[_c('div',{staticClass:"legup-actions__button-content"},[_vm._v("\n              Join waitlist\n            ")])])],1)]:_vm._e(),_vm._v(" "),(_vm.isAuthorized && _vm.facility)?[_c('contact-button',{attrs:{"provider-id":_vm.facility.providerId,"facility-id":_vm.facility.id,"email":_vm.facility.providerEmail,"phone-number":_vm.facility.phoneNumber,"facility-class":_vm.facility.facilityClass,"facility-name":_vm.facility.name,"full-width":true,"last-message-date":_vm.lastMessageDate}})]:(!_vm.isAuthorized)?[_c('contact-button',{attrs:{"provider-id":0,"facility-id":0,"email":'',"phone-number":'',"facility-class":'',"facility-name":'',"full-width":true,"last-message-date":''}})]:_vm._e()],2)]),_vm._v(" "),_c('profile-mobile-footer-nav',{attrs:{"loading":_vm.loading,"facility":_vm.facility,"lastMessageDate":_vm.lastMessageDate,"seatsAvailable":_vm.seatsAvailable}},[(!_vm.loading)?[(_vm.tourEnabled && !_vm.isLegupTourBooked)?_c('system-button',_vm._g({staticClass:"tour-button",attrs:{"fa-icon":"calendar","target":"_blank","full-width":""}},!this.currentUserId() ? { click: () => _vm.openAuthModal('tour') } : !_vm.isPreviewMode
          ? { click: () => _vm.openTourModal() }  :  {}),[_c('div',{staticClass:"legup-actions__button-content"},[_vm._v("\n          Book a tour\n        ")])]):((_vm.isLegupTourBooked || !_vm.tourEnabled) && !_vm.isLegupWaitlistJoined)?_c('system-button',_vm._g({staticClass:"legup-actions__button",attrs:{"style-type":"secondary","target":"_blank","full-width":"","disabled":_vm.disableButton}},!this.currentUserId()
          ? { click: () => _vm.openAuthModal('waitlist') } : !_vm.isPreviewMode
          ? { click: () => _vm.openWaitlistModal() } : {}),[_c('div',{staticClass:"legup-actions__button-content"},[_vm._v("\n          Join waitlist\n        ")])]):((_vm.isLegupTourBooked || !_vm.tourEnabled) && _vm.isLegupWaitlistJoined)?_c('box',{staticClass:"mark-done",attrs:{"no-icon-bg":true,"faIcon":"check-circle"}},[_vm._v("\n        Tour booked\n      ")]):_vm._e()]:_vm._e()],2),_vm._v(" "),(_vm.loading)?[_c('loader',{attrs:{"loading":_vm.loading}})]:_vm._e(),_vm._v(" "),(_vm.isWaitlistEnabled)?[_c('legup-waitlist',{attrs:{"provider":_vm.provider,"isOpen":_vm.isWaitlistModalOpen,"closeModal":_vm.closeWaitlistModal,"dependentsWithSpots":_vm.dependentsWithSpots,"waitlistEnabled":_vm.waitlistEnabled,"tourBooked":_vm.isLegupTourBooked},on:{"waitlist-joined":_vm.waitlistJoined,"open-tour-modal":_vm.openTourModal}})]:_vm._e(),_vm._v(" "),(_vm.enableSendPayment)?[_c('box',{staticClass:"mt-2 align-center",attrs:{"color":"white","big-radius":true}},[_c('span',{staticClass:"dollar-icon box--gold"},[_c('i',{staticClass:"fal fa-dollar-sign"})]),_vm._v(" "),_c('p',{staticClass:"pay-provider font-body-large legup-actions__payment-box-header"},[_vm._v("\n        Send a quick payment\n      ")]),_vm._v(" "),_c('preview-mode-tooltip',{attrs:{"tooltipLabel":"Send a payment","tooltipText":"This is how a parent can send you a payment via Kinside\n          for their tuition, deposit, and/or any other fees at your program."}},[_c('system-button',{attrs:{"to":_vm.sendPaymentRoute(),"full-width":"","color":"gold"}},[_vm._v("\n          Pay provider\n        ")])],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }