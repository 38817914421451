<template>
  <navigation-bar
      :hide-navigation="hideNavigation"
      :hideLinks="hideLinks"
      :avatar="avatar"
      :hide-bar="hideBar"
  >
    <template #nav>
      <user-access can="doKinsideFeatures">
        <navigation-item
          fa-icon="search"
          :to="{ name: 'childcare' }"
          short="Find"
          smallerPaddingOnTablets
        >
          Find child care
        </navigation-item>
      </user-access>
      <user-access can="doKinsideFeatures">
        <navigation-item
          fa-icon="heart"
          :to="{ name: 'my-programs' }"
          short="Programs"
          smallerPaddingOnTablets
        >
          My programs
        </navigation-item>
      </user-access>
      <user-access can="doKinsideFeatures">
        <navigation-item
          fa-icon="message"
          :to="{ name: 'messaging' }"
          :unreadCount=unreadCount
          short="Messages"
          smallerPaddingOnTablets
        >
          Messages
        </navigation-item>
      </user-access>
      <user-access can="doKinsideFeatures">
        <user-access can="doPayments">
          <navigation-item
            fa-icon="circle-dollar"
            :to="{ name: 'payments' }"
            short="Pay"
            smallerPaddingOnTablets
          >
            My payments
          </navigation-item>
        </user-access>
      </user-access>
    </template>

    <slot></slot>

  </navigation-bar>
</template>

<script>
import NavigationBar from '@components/navigation/navigation-bar.vue';
import NavigationItem from '@components/navigation/navigation-item.vue';
import authMixin from '@profile/mixins/auth';
import appcuesMixin from '@mixins/appcues';

export default {
  name: 'ParentsNavigation',
  mixins: [
    authMixin,
    appcuesMixin,
  ],
  props: {
    avatar: {
      type: String,
      default: '',
    },
  },
  components: {
    NavigationBar,
    NavigationItem,
  },
  data() {
    return {
      userIdentifiedWithAppcues: false,
    };
  },
  watch: {
    '$store.state.user.current.id': {
      immediate: true,
      handler(userIdentifier) {
        const userProperties = {
          userType: 'parent',
          companyName: this.$store.state.user.current.company,
          isPayingCompany: this.$store.state.user.current.isPayingCompany,
          isPaymentCollectionUser: this.$store.state.user.current.isPaymentCollectionUser,
          conciergeSubscriptionEnabled: this.$store.state.user.current.conciergeSubscriptionEnabled,
        };
        this.identifyUserWithAppcues(userIdentifier, userProperties);
      },
    },
  },
  mounted() {
    if (this.isAuthorized) {
      this.$store.dispatch('messaging/fetchUnreadBadge');
    }
  },
  computed: {
    userCanEnterApp() {
      return !!this.$store.state.user.current.email && this.$userAccess({ can: 'enterApp' });
    },
    hideNavigation() {
      return this.$route.name === 'onboarding';
    },
    hideLinks() {
      return this.$route.name === 'accept-autopay';
    },
    hideBar() {
      return this.isPreviewMode || (this.isMobile && this.$route.name === 'facility-profile' && this.isAuthorized);
    },
    isPreviewMode() {
      return this.$store.getters.isPreviewMode;
    },
    unreadCount() {
      return this.$store.state.messaging.unreadBadge;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-navigation {
  align-items: center;

  &__nav-item {
    padding: var(--grid-unit) var(--grid-unit);
    margin: 0 calc(var(--grid-unit) / 2);
    font-size: 1rem;
    font-weight: 400;
    color: var(--viridian-0);
    border-top: 3px solid var(--viridian-50);
    border-bottom: 3px solid var(--viridian-50);

    @media screen and (min-width: 768px) {
      margin: 0 var(--grid-unit);
    }

    .fal {
      padding-right: calc(var(--grid-unit) / 2);
    }
  }
}
</style>
