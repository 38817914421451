<template>
  <div class="waitlist-payment-form">
    <table class="waitlist-payment-form--table">
      <tr>
        <td>Waitlist fee</td>
        <td class="waitlist-payment-form--right-cell">
          {{ formattedCalculatedWaitlistFeeFromProvider }}
        </td>
      </tr>
    </table>

    <hr/>

    <div v-if="discountSuccess">
      <table class="waitlist-payment-form--table">
        <tr>
          <td>Discount</td>
          <td class="waitlist-payment-form--right-cell">-{{ formattedDiscountApplied }}</td>
        </tr>
      </table>

      <hr/>
    </div>

    <table class="waitlist-payment-form--table">
      <tr>
        <td>Service fee</td>
        <td class="waitlist-payment-form--right-cell">{{ formattedCommissionFee }}</td>
      </tr>
    </table>

    <hr/>

    <table class="waitlist-payment-form--table">
      <tr>
        <td>Total due</td>
        <td class="waitlist-payment-form--right-cell">{{ formattedTotalToBePaid }}</td>
      </tr>
    </table>
    <hr/>
    <div v-if="calculatedWaitlistFeeFromProvider && discountTotalNullOrNotZero">
      <payment-method-card
        v-for="method in currentPayment.paymentRequestMethods"
        :key="'payment-method__' + method.paymentMethodId"
        v-bind="method"
        v-model.number="method.totalAmount"
      />
      <br/>
      <select-payment-method
        @method-selected="updateMethod"
        :payment-flow="paymentFlow"
      />
    </div>
    <k-form-field v-show="!automaticDiscount && hasCoupons" class="margin-top">
      <k-checkbox
        v-model="haveDiscount"
        name="haveDiscount"
        :disabled="discountSuccess"
      >
        I have a discount code
      </k-checkbox>
    </k-form-field>
    <div v-if="!automaticDiscount && haveDiscount" class="discount-input-container">
      <input
        v-model="couponCode"
        class="discount-input"
        :placeholder="codePlaceholder"
        :class="{
          'discount-success-input': discountSuccess,
          'discount-failure-input': discountFailure,
        }"
      />
      <system-button
        v-if="!discountSuccess"
        @click="validateCouponCode"
        styleType="secondary">
        Apply discount
      </system-button>
      <small v-if="discountFailure" class="discount-failure-text margin-top">
        That code is invalid.
      </small>
      <span v-if="discountSuccess" class="discount-success-text">
        <span class="far fa-circle-check icon"></span>
        Discount applied
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getDollarFormat, toSnakeCase } from '@utils';
import KFormField from '@components/forms/form_field.vue';
import KCheckbox from '@components/inputs/checkbox.vue';
import SystemButton from '@components/buttons/system-button.vue';
import PaymentMethodCard from '../payments/payment-method-card/payment-method-card.vue';
import SelectPaymentMethod from '../payments/send-payment/select-payment-method.vue';
import { recalculateAmount } from '../../../common/stores/modules/payments.service';

export default {
  name: 'payment-form',
  components: {
    PaymentMethodCard,
    KFormField,
    KCheckbox,
    SystemButton,
    SelectPaymentMethod,
  },
  props: {
    waitlist: {
      type: Object,
      default() {
        return {};
      },
    },
    bus: {
      type: Object,
      required: true,
    },
    submitStep: {
      type: Function,
      required: true,
    },
    setLoading: {
      type: Function,
    },
  },
  data() {
    return {
      haveDiscount: false,
      couponCode: '',
      automaticDiscount: this.waitlist.automaticDiscount,
      discountSuccess: false,
      discountFailure: false,
      codePlaceholder: this.$store.state.legup.couponInfo.code || '',
    };
  },
  computed: {
    ...mapGetters({
      currentPayment: 'payments/currentPayment',
      totalFeesAmount: 'payments/currentPaymentTotalFees',
    }),
    paymentFlow() {
      return 'waitlist';
    },

    commissionFee() {
      if (Number.isNaN(this.totalFeesAmount)) {
        return 0;
      }

      if (this.discountedTotal === 0) {
        return 0;
      }

      return this.totalFeesAmount;
    },

    formattedCommissionFee() {
      if (this.commissionFee === 0) {
        return '$--';
      }
      return getDollarFormat(this.commissionFee);
    },
    discountTotalNullOrNotZero() {
      return this.discountedTotal == null || this.discountedTotal !== 0;
    },
    formattedDiscountApplied() {
      let discountAmount;
      if (this.legupStore.couponInfo) {
        discountAmount = this.legupStore.couponInfo.initial_total
          - this.legupStore.couponInfo.discounted_total;
      }
      if (Object.keys(this.legupStore.couponInfo).length === 0
        && this.currentPayment.totalToBeCharged === 0) {
        discountAmount = this.legupStore.waitlistFees;
      }
      return getDollarFormat(discountAmount);
    },

    legupStore() {
      return this.$store.state.legup;
    },

    discountedTotal() {
      return this.legupStore.couponInfo?.discounted_total;
    },

    totalToBePaid() {
      let computedAmount = this.currentPaymentAmount;

      if (this.commissionFee > 0) {
        computedAmount += this.commissionFee;
      }

      return computedAmount;
    },

    formattedTotalToBePaid() {
      return getDollarFormat(this.totalToBePaid);
    },

    calculatedWaitlistFeeFromProvider() {
      return this.legupStore.waitlistFees;
    },

    formattedCalculatedWaitlistFeeFromProvider() {
      return getDollarFormat(this.calculatedWaitlistFeeFromProvider);
    },

    currentPaymentAmount() {
      return this.currentPayment.amount;
    },

    hasCoupons() {
      return this.legupStore.providerCoupons.length;
    },
    paymentMethods() {
      return this.currentPayment.paymentRequestMethods;
    },
  },

  async created() {
    await this.updateCalculations(this.legupStore.waitlistFees);
    await this.updateDiscountStatus();
    this.bus.$on('on-next-step', this.handleNext);
  },

  beforeDestroy() {
    this.bus.$off('on-next-step', this.handleNext);
  },

  methods: {
    async updateDiscountStatus() {
      if (Object.keys(this.legupStore.couponInfo).length > 0) {
        await this.updateCalculations(this.discountedTotal);
        this.discountSuccess = true;
        this.haveDiscount = true;
        return;
      }
      if (this.waitlist.waitlistData.subsidies.length > 0) {
        await this.updateCalculations(this.discountedTotal);
        this.automaticDiscount = true;
        this.discountSuccess = true;
      }
    },

    async updateCalculations(amount) {
      await this.$store.dispatch('payments/setTotalAmount', amount);
    },

    async updateMethod(paymentMethodId, amount) {
      let amountToApply = amount !== undefined ? amount : this.currentPaymentAmount;

      if (this.paymentMethods?.length > 1) {
        amountToApply /= this.paymentMethods.length;
      }

      await this.$store.dispatch('payments/updateMethodInCurrentPayment', {
        paymentMethodId,
        totalAmount: amountToApply,
        includesFees: false,
      });
    },

    async submitWaitlistPayment() {
      this.setLoading(true);
      const coupon = this.legupStore.couponInfo || {};
      const invite = this.$route.query.open_waitlist_form;
      const amount = this.currentPayment.amount ?? 0; // amount with kinside fee
      const params = {
        facility_id: this.waitlist.facilityId,
        center_id: this.waitlist.centerId,
        message_to_provider: this.waitlist.waitlistData.parentComment,
        dependent_id: this.waitlist.waitlistData.dependentId,
        payment_request_methods: toSnakeCase(this.paymentMethods)
          .map((method) => ({ amount: method.initial_amount, ...method })),
        preferred_start_date: this.waitlist.waitlistData.startCare,
        phone_number: this.waitlist.waitlistData.phoneNumber,
        provider_id: this.waitlist.providerId,
        parent_id: this.waitlist.parentId,
        selected_schedules: this.waitlist.waitlistData.selectedSchedule,
        age_group_waitlist: true,
        answers: this.waitlist.waitlistData.answers || {},
        coupon,
        commission: this.commissionFee,
        amount,
        invite,
      };
      try {
        await this.$store.dispatch('sendWaitlistSpotRequest', params);
        this.submitStep();
      } catch (error) {
        this.$store.dispatch('notifications/addToastError', { text: 'Could not create the waitlist spot. Refresh the page to try again, or contact concierge@kinside.com for more help.', error }, { root: true });
      }
      this.setLoading(false);
    },

    async validateCouponCode() {
      const params = {
        coupon_code: this.couponCode,
        provider_id: this.waitlist.providerId,
        coupon_type: 'waitlist',
        waitlist_fee_total: this.currentPaymentAmount,
      };
      await this.$store.dispatch('legup/validateProviderWaitlistCoupon', params);
      // eslint-disable-next-line no-unused-expressions
      if (Object.keys(this.legupStore.couponInfo).length > 0) {
        this.discountSuccess = true;
        this.discountFailure = false;
        await this.updateCalculations(this.discountedTotal);
      } else {
        this.discountFailure = true;
        this.discountSuccess = false;
      }
      // if there are payment methods and the total after the discount is 0
      // we will just update the payment methods directly and not do the api calls
      if (this.paymentMethods?.length > 0 && this.discountedTotal === 0) {
        this.paymentMethods.forEach((pm) => {
          // eslint-disable-next-line no-param-reassign
          pm.totalAmount = this.discountedTotal;
          // eslint-disable-next-line no-param-reassign
          pm.commissionAmount = this.discountedTotal;
        });
      }
      // when payment method(s) already exist when the parent adds a discount code,
      // and there total is not 0, recalculate the payment method amounts
      if (this.paymentMethods?.length > 0 && this.discountedTotal !== 0) {
        const data = await recalculateAmount({
          paymentRequestMethods: this.paymentMethods,
          totalAmount: this.currentPayment.amount,
        });
        const firstPaymentMethodId = this.paymentMethods[0].paymentMethodId;
        if (firstPaymentMethodId) {
          await this.updateMethod(firstPaymentMethodId, data.totalToBeCharged);
        }
      }
    },

    async handleNext() {
      await this.submitWaitlistPayment();
    },
  },
};
</script>
<style lang="scss" scoped>
.waitlist-payment-form {
  max-width: 400px;
  width: 100%;
  padding-top: 20px;
  margin: auto;

  &--table {
    width: 100%;
  }

  &--right-cell {
    text-align: right;
  }
}

.discount-input-container {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}

.margin-top {
  margin-top: calc(var(--grid-unit) * 2);
}

.discount-input {
  padding: var(--grid-unit);
  margin-right: calc(var(--grid-unit) * 2);
  border: 1px solid var(--gray-60);
  border-radius: var(--grid-unit);
}

.discount-success-input {
  background-color: var(--gray-15);
  border-color: var(--gray-20);
}

.discount-success-text {
  padding-top: 10px;
  color: var(--viridian-40);
}

.discount-failure-input {
  color: var(--rust-50);
  border-color: var(--rust-50);
  box-shadow: 0 0 0 2px var(--rust-10);
}

.discount-failure-text {
  color: var(--rust-50);
}
</style>
