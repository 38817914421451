<template>
  <div>
    <offer v-for="offer in offers" :key="offer.id" v-bind="offer" />
  </div>
</template>

<script>
import Offer from './offer.vue';

export default {
  components: {
    Offer,
  },
  props: {
    offers: {
      type: Array,
      default: undefined,
      validator(value) {
        return Array.isArray(value) && value.every((item) => !!item.title);
      },
    },
  },
};
</script>
