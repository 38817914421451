<template>
  <div v-if="isWaitlistEnabled && showContent">
    <box class="waitlist" color="viridian-light" v-if="!isLegupWaitlistJoined">
      <div class="mb-1">Don’t see a spot that fits your needs right now?</div>
      <system-button
        style-type="secondary"
        v-on="!this.isAuthorized
          ? { click: () => openAuthModal('waitlist') } : !isPreviewMode
          ? { click: () => openWaitlistModal() } : {}"
      >
        Join waitlist
      </system-button>

      <legup-waitlist
        :provider="provider"
        :isOpen="isWaitlistModalOpen"
        :closeModal="closeWaitlistModal"
        @waitlist-joined="getWaitlistSpotCenters"
        :dependentsWithSpots="dependentsWithSpots"
        :waitlistEnabled="waitlistEnabled"
      />
    </box>

    <box class="waitlist" color="periwinkle-light" v-else>
      <div class="title mb-2">Waitlist joined</div>
      <div class="mb-1">We’ll let you know when the provider has a spot for you.</div>
      <system-button
        style-type="clear"
        color="periwinkle"
        @click="openEditModal()"
      >
        Edit preferences
      </system-button>

      <edit-waitlist
        :isOpen="isEditModalOpen"
        :closeModal="closeEditModal"
        :waitlistSpot="toCamelCase(spot.waitlist_spot)"
        :centerId="spot.center_id"
        :centerName="spot.center_name"
        :ageGroupId="spot.age_group_id"
        :childName="spot.child_name"
      />
    </box>
  </div>
</template>

<script>
import SystemButton from '@components/buttons/system-button.vue';
import Box from '@components/box.vue';
import waitlistMixin from '@profile/mixins/waitlist';
import LegupWaitlist from '@components/facility-profile/legup-waitlist.vue';
import profileComponentMixin from '@profile/mixins/profile-component';
import EditWaitlist from '@parent/components/edit-waitlist/edit-waitlist.vue';

export default {
  name: 'profile-waitlist-mobile',

  props: {
    tourEnabled: {
      type: Boolean,
      default: false,
    },
    waitlistEnabled: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [
    waitlistMixin,
    profileComponentMixin,
  ],

  components: {
    SystemButton,
    LegupWaitlist,
    Box,
    EditWaitlist,
  },

  computed: {
    isWaitlistEnabled() {
      if (!this.isAuthorized) return true;
      if (this.waitlistEnabled) return true;
      if (!this.waitlistEnabled
        && this.tourEnabled) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.waitlist {
  padding: calc(var(--grid-unit) * 2);
  text-align: center;
  border-radius: calc(var(--grid-unit) * 2);

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.title {
  font-size: var(--font-size-20);
  font-family: var(--serif);
}
</style>
