<template>
  <Tooltip
    theme="kinside-info"
    class="opinion-bar-button-wrapper__tooltip"
    :disabled="!$store.getters.isPreviewMode"
    :triggers="['hover', 'click', 'touch']"
  >
    <slot></slot>
    <template #popper>
      <b>{{tooltipLabel}}</b><br>
      {{tooltipText}}
    </template>
  </Tooltip>
</template>

<script>
import { Tooltip } from 'floating-vue';

export default {
  components: {
    Tooltip,
  },
  props: {
    tooltipLabel: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.opinion-bar-button-wrapper__tooltip {
  display: flex;
  justify-content: center;
}
</style>
