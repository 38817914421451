<template>
  <opinion-bar
    :is-favorited="isFavorited"
    :is-saved="isSaved"
    :is-ignored="isIgnored"
    @toggle-opinion="toggleOpinion"
    :position="position"
  />
</template>

<script>
import authMixin from '@profile/mixins/auth';
import OpinionBar from './opinion-bar.vue';

export default {
  components: {
    OpinionBar,
  },
  mixins: [authMixin],
  props: {
    facilityId: {
      type: Number,
      required: true,
    },
    position: {
      type: String,
      default: 'top',
      validator: (value) => ['top', 'bottom'].includes(value),
    },
  },
  computed: {
    isFavorited() {
      return this.$store.getters['facilities/facilityIsFavorited'](this.facilityId);
    },
    isSaved() {
      return this.$store.getters['facilities/facilityIsSaved'](this.facilityId);
    },
    isIgnored() {
      return this.$store.getters['facilities/facilityIsIgnored'](this.facilityId);
    },
  },
  methods: {
    async toggleOpinion(opinion) {
      if (!this.isAuthorized) {
        this.openAuthModal('opinion');
      } else {
        if (await this.$store.dispatch('hasImpersonationError')) {
          return;
        }

        await this.$store.dispatch(`facilities/toggle${opinion}`, {
          facilityId: this.facilityId,
          context: this.$router.currentRoute.name,
        });
      }
    },
  },
};
</script>
