<template>
  <modal
    :is-open="isOpen"
    @close="closeModal"
  >
    <template #header>
      <h6>Schedule tour at <strong>{{ facilityName }}</strong></h6>
    </template>
    <div v-if="loading">
      <span class="fas fa-spinner fa-pulse"></span>
    </div>

    <div v-if="provider.tourMode === 'tour_slots_from_facility'">

      <p v-if="provider.noTours">
        This facility does not have any tour openings
      </p>

      <p v-if="!provider.noTours">
        Select a date and time that suits you best
      </p>

      <div
        v-for="day in slotDays"
        :key="day"
        class="day-row"
      >
        <div>
          {{ day | moment('dddd [•] MMM D') }}
        </div>
        <div>
          <!-- FIXME: that should be radio picker -->
          <system-button
            v-for="tourSlot in provider.slots[day]"
            :key="day + (tourSlot.startAt || moment('h:mm A')) "
            :style-type="(selectedTourSlotId === tourSlot.id) ? 'primary': 'secondary'"
            :color="(selectedTourSlotId === tourSlot.id) ? 'viridian': 'gray'"
            full-width
            @click="() => selectTourSlot(tourSlot.id)"
          >
            {{ tourSlot.startAt | moment('timezone', provider.timezone, 'h:mm A') }}
          </system-button>
        </div>
      </div>

      <div class="button-container">
        <system-button
          v-if="!provider.noTours && provider.slots && !loading"
          style-type="secondary"
          @click="provider.fetchSlots"
          small
        >
          View more
        </system-button>
      </div>
    </div>

    <div v-if="provider.tourMode === 'parent_tour_slots'">
      <p>
        Please provide the time that would be suitable
        for you to take a tour. We will contact the
        facility and let you know which time fits them.
      </p>
      <box class= "time-zone-box"
           fa-icon="fa fa-exclamation-circle"
           :no-icon-bg="true"
           color="gold">
        {{ `All times in ${timeZoneAbbr}` }}
      </box>
      <div>
        <div class="parent-slot-row">
          <label>
            Select date
          </label>
          <label>
            Set time
          </label>
        </div>
        <div
          v-for="parentSlot in parentSlots"
          :key="`${parentSlot.date}-${parentSlot.time.from}${parentSlot.time.to}-${parentSlot.id}`"
          class="parent-slot-row"
        >
          <date-input
            v-model="parentSlot.date"
            :min="minDate()"
          />

          <time-range-input
            v-model="parentSlot.time"
          />

          <system-button
            @click="removeParentSlot(parentSlot)"
            v-if="parentSlots.length > 1"
            style-type="clear"
            color="rust"
            fa-icon="minus-circle"
            small
            title="Remove this parent slot"
          >
          </system-button>
        </div>

        <system-button @click="addParentSlot" style-type="secondary" small>
          + Add more
        </system-button>

      </div>
    </div>

    <div v-if="displayCommentTextarea" class="textarea-container">
      <k-textarea v-model="parentComment" placeholder="Message" autogrow></k-textarea>
      <label class="font-body-small">
        {{provider.tourMode === 'parent_tour_slots'
        ? 'Start conversation with caregiver'
        : 'optional'}}
      </label>
    </div>

    <template #footer>
      <system-button
        style-type="secondary"
        @click="closeModal">
        Cancel
      </system-button>
      <system-button
        @click="confirmButton.action"
        :disabled="confirmButton.disabled">
        {{ confirmButton.label }}
      </system-button>
    </template>
  </modal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import Box from '@components/box.vue';
import addBusinessDays from '@utils/addBusinessDays';
import DateInput from '@components/inputs/date-input.vue';
import TimeRangeInput from '@components/inputs/time-range-input.vue';
import providerProp from '@mixins/providerProp';
import KTextarea from '@components/inputs/textarea.vue';
import Modal from '@components/modal/modal.vue';
import { escapeHtml } from '@utils';
import SystemButton from '../buttons/system-button.vue';
import timeZoneAbbr from '../../mixins/timeZoneAbbr';

export default {
  components: {
    Modal,
    DateInput,
    SystemButton,
    KTextarea,
    TimeRangeInput,
    Box,
  },
  mixins: [providerProp, timeZoneAbbr],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      selectedTourSlotId: null,
      parentComment: '',
      parentSlots: [],
    };
  },
  async mounted() {
    if (this.$store.getters.isPreviewMode) return;

    this.loading = true;
    await this.provider.fetchFacilityTourMode();
    if (this.provider.tourMode === 'parent_tour_slots') {
      await this.provider.fetchParentSlots();
      this.parentSlots = [...this.provider.parentSlots];
    } else {
      await this.provider.fetchSlots();
    }
    this.loading = false;
  },

  computed: {
    impersonatedUser() {
      return this.$store.state.user.current.isImpersonated;
    },
    slotDays() {
      return !this.provider.noTours && this.provider.slots ? Object.keys(this.provider.slots) : [];
    },
    displayCommentTextarea() {
      return this.provider.tourMode === 'tour_slots_from_facility'
        ? !this.provider.noTours
        : this.parentSlots.length;
    },
    facilityName() {
      return this.provider.currFacility.name;
    },
    confirmButton() {
      if (this.provider.tourMode === 'tour_slots_from_facility') {
        return {
          label: 'Book',
          disabled: !this.selectedTourSlotId,
          action: this.bookTourSlot,
        };
      }
      if (this.provider.tourMode === 'parent_tour_slots') {
        return {
          label: 'Request Tour Booking',
          disabled: !this.parentSlots.length,
          action: this.requestTourBooking,
        };
      }
      return {
        action: this.closeModal,
      };
    },
  },

  methods: {
    minDate() {
      return this.impersonatedUser ? new Date() : this.after2BusinessDays();
    },

    after2BusinessDays() {
      const today = new Date();
      return addBusinessDays(today, 2);
    },

    tourSlotsPerDay(day) {
      const dayFormatted = this.$moment(day).format('YYYY-MM-DD');
      return this.provider.slots[dayFormatted] || [];
    },

    selectTourSlot(tourId) {
      this.selectedTourSlotId = tourId;
    },

    async reserveTour(tourSlotId, useParentSlots) {
      const tourData = {
        useParentSlots,
        tourSlotId,
        comment: this.parentComment ? escapeHtml(this.parentComment).join(' ') : '',
      };
      const redirectUrl = await this.provider.reserveTour(tourData);
      if (redirectUrl !== false) this.$emit('tour-reserved');
      this.closeModal();
      if (redirectUrl) window.open(redirectUrl, '_blank');
    },

    bookTourSlot() {
      this.reserveTour(this.selectedTourSlotId);
    },

    addParentSlot() {
      if (!this.parentSlots.length) {
        const newSlot = {
          id: -1,
          date: this.minDate(),
          time: {
            from: '8:00 am',
            to: '9:00 am',
          },
        };
        this.parentSlots = [...this.parentSlots, newSlot];
      } else {
        // if last slot time is after 11pm
        // set date to next day and
        // set new slot time to 08:00 am - 09:00 am
        const lastSlot = this.parentSlots[(this.parentSlots.length - 1)];
        const lastSlotFrom = this.$moment(lastSlot.time.from, 'h:mm a');
        const lastHourForDay = this.$moment('11:00pm', 'h:mm a');
        const isLastSlotOfDay = !lastSlotFrom.isBefore(lastHourForDay);
        let newSlotTime = {};
        let newSlotDate = '';
        if (isLastSlotOfDay) {
          newSlotDate = this.$moment(lastSlot.date, 'YYYY-MM-DD').add(1, 'day')
            .format('YYYY-MM-DD');
          newSlotTime = {
            from: '8:00 am',
            to: '9:00 am',
          };
        } else {
          newSlotDate = lastSlot.date;
          newSlotTime = {
            from: this.$moment(lastSlot.time.to, 'h:mm a').add(1, 'h').format('h:mm a'),
            to: this.$moment(lastSlot.time.to, 'h:mm a').add(2, 'h').format('h:mm a'),
          };
        }
        const newSlot = {
          id: -1,
          date: newSlotDate,
          time: newSlotTime,
        };
        this.parentSlots = [...this.parentSlots, newSlot];
      }
    },

    removeParentSlot(slot) {
      const slots = [...this.parentSlots]
        .filter((s) => {
          if (slot.id !== -1) {
            return s.id !== slot.id;
          }
          return s.date !== slot.date
            || s.time.from !== slot.time.from
            || s.time.to !== slot.time.to;
        });
      this.parentSlots = slots;
    },

    async requestTourBooking() {
      let newParentSlots = cloneDeep(this.parentSlots);

      if (!this.impersonatedUser) {
        const after2Days = this.after2BusinessDays();
        const tzOffset = (new Date()).getTimezoneOffset() * 60000;
        const after2BDaysStringWithTz = (new Date(after2Days - tzOffset)).toISOString().split('T')[0];
        const after2BDaysString = after2Days.toISOString().split('T')[0];
        const availableOpenSlots = [];
        const firstSlot = newParentSlots[0];

        for (let i = 0; i < newParentSlots.length; i += 1) {
          if (newParentSlots[i].date >= after2BDaysString) {
            availableOpenSlots.push(newParentSlots[i]);
          }
        }

        newParentSlots = availableOpenSlots;
        if (newParentSlots.length === 0) {
          firstSlot.date = after2BDaysString;
          newParentSlots.push(firstSlot);
          this.provider.addToastError(`Tours can only be booked starting from ${after2BDaysStringWithTz}`);
          return;
        }
      }
      // format times to format that updateParentSlots is
      // expecting before saving/updating
      newParentSlots = newParentSlots.map((slot) => {
        const newSlot = { ...slot };
        newSlot.time.from = this.$moment(newSlot.time.from, 'h:mm a').format('h:mm A');
        newSlot.time.to = this.$moment(newSlot.time.to, 'h:mm a').format('h:mm A');
        return newSlot;
      });
      await this.provider.updateParentSlots(newParentSlots);
      await this.reserveTour(null, true);
    },

    closeModal() {
      this.provider.closeScheduleTourModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.time-zone-box {
  width: fit-content;
  padding: 5px 10px;
  margin: 12px 0;
}

.parent-slot-row {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(var(--grid-unit) / 2);
  justify-items: start;
  margin-bottom: calc(var(--grid-unit) / 2);

  @media screen and (min-width: 768px) {
    grid-template-columns: 190px 1fr 20px;
    justify-items: stretch;
  }

  > * {
    margin-bottom: 0;
  }
}

.day-row {
  display: flex;
  margin-bottom: var(--grid-unit);

  & > *:first-child {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  & > *:nth-child(2) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: calc(var(--grid-unit) / 2);
    width: 100%;
  }
}

.textarea-container {
  margin-top: calc(var(--grid-unit) * 3);
}

.button-container {
  text-align: center;
}
</style>
