<template>
  <div>
    <template v-if="showContent">
      <template v-if="isAuthorized">
        <tour-booked
          v-if="showLegupActions && isLegupTourBooked"
          :tour="upcomingTour"
          :timezone="facility.timezone"
          @edit-tour="openEditTour"/>
        <waitlist-joined v-if="showLegupActions && isLegupWaitlistJoined" />
      </template>

      <legup-actions
        v-if="showLegupActions"
        :trackClick="trackLegupActionClick"
        :editTour="editTour"
        :upcomingBookedTour="upcomingTour"
        :isTourModalOpen="tourOpen"
        @close-tour-modal="tourModal(false)"
        @open-tour-modal="tourModal(true)"
        @tour-booked="updateTour"
        @tour-cancelled="onTourCancelled"
        @waitlist-joined="getWaitlistSpotCenters"
        :logoUrl="logoUrl"
        :tourEnabled="tourEnabled"
        :waitlistEnabled="waitlistEnabled"
        :seatsAvailable="seatsAvailable"
        :enable-send-payment="enableSendPayment"
      />
      <template v-if="!showLegupActions">
        <facility-actions
          :provider="provider"
          :preview="isPreviewMode"
        />
        <enrollment-modal
          :provider="provider"
        />
      </template>
    </template>
  </div>
</template>

<script>
import LegupActions from '@components/facility-profile/legup-actions.vue';
import FacilityActions from '@components/facility-profile/facility-actions.vue';
import TourBooked from '@parent/components/schedule-tour/tour-booked.vue';
import EnrollmentModal from '@components/facility-profile/enrollment-modal.vue';
import WaitlistJoined from '@parent/components/join-waitlist/waitlist-joined.vue';
import tourMixin from '@profile/mixins/tour';
import waitlistMixin from '@profile/mixins/waitlist';
import profileComponentMixin from '@profile/mixins/profile-component';

export default {
  name: 'profile-cta',

  mixins: [
    tourMixin,
    waitlistMixin,
    profileComponentMixin,
  ],

  components: {
    LegupActions,
    TourBooked,
    WaitlistJoined,
    FacilityActions,
    EnrollmentModal,
  },

  props: {
    logoUrl: {
      type: String,
      default: '',
    },
    seatsAvailable: {
      type: Boolean,
      default: false,
    },
    tourEnabled: {
      type: Boolean,
      default: false,
    },
    waitlistEnabled: {
      type: Boolean,
      default: false,
    },
    enableSendPayment: {
      type: Boolean,
      default: false,
    },
  },
  beforeDestroy() {
    if (this.provider.isContactFormRequested) {
      this.provider.setState({ isContactFormRequested: false });
    }
  },
};
</script>
