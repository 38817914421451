export default (date, days) => {
  let newDate = date;
  if (!(date instanceof Date)) newDate = new Date();

  let daysToAdd = parseInt(days, 10);
  const dow = newDate.getDay();

  // If the current day is Saturday or Sunday add one day
  if (dow === 0) daysToAdd += 1;

  // If the start date plus the additional days falls on or after
  // the closest Saturday calculate weekends
  if (dow + daysToAdd >= 6) {
    // Subtract days in current working week from work days
    const remainingWorkDays = daysToAdd - (5 - dow);
    // Add current working week's weekend
    daysToAdd += 2;
    if (remainingWorkDays > 5) {
      // Add two days for each working week by calculating how many weeks are included
      daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
      // Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
      if (remainingWorkDays % 5 === 0) daysToAdd -= 2;
    }
  }
  newDate.setDate(date.getDate() + daysToAdd);

  return newDate;
};
