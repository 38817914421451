<template>
  <div class="text-center">
    <img
      height="80"
      class="mg-b-24"
      src="https://res.cloudinary.com/kinside/image/upload/v1648078117/app_assets/illustration-child1.png"
    >
    <h6 class="page__header font-heading-30 margin-center mg-b-24">
      Hooray! You joined the waitlist at <strong>{{ facilityName }}.</strong>
    </h6>
    <p class="p-s-24">
      We sent a receipt to  {{ parentEmail }}.
      We'll let you know when the provider has a spot for you.
    </p>
  </div>
</template>

<script>

export default {
  name: 'confirmtion',
  props: {
    waitlist: {
      type: Object,
      default() {
        return {};
      },
    },
    facilityName: {
      type: String,
    },
    bus: {
      type: Object,
      required: true,
    },
    submitStep: {
      type: Function,
      required: true,
    },
  },

  created() {
    this.bus.$on('on-next-step', this.handleNext);
  },

  beforeDestroy() {
    this.bus.$off('on-next-step', this.handleNext);
  },

  methods: {
    async handleNext() {
      this.submitStep();
    },
  },

  computed: {
    parentEmail() {
      const { email } = this.$store.getters['user/currentParent'] || {};
      return email;
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}

.margin-center {
  margin: auto;
}

.mg-b-24 {
  margin-bottom: calc(var(--grid-unit) * 3);
}

.center {
  justify-content: center;
}

.p-s-24 {
  padding: 0 calc(var(--grid-unit) * 3);
}
</style>
