<template>
  <user-access :role-is="['impersonated', 'admin', 'concierge']" :user="user">
    <template>
      <div v-if="type==='button'" :class="classes" v-bind="$attrs"><slot></slot></div>
      <div v-else :class="classes" v-bind="$attrs">
        <i v-if="type !== 'button' && showIcon" class="fal fa-eye"></i>
        <template>
          <slot></slot>
        </template>
      </div>
    </template>
    <template #else>
      <slot name="else"></slot>
    </template>
  </user-access>
</template>

<script>
import UserAccess from './user-access';

export default {
  components: { UserAccess },
  props: {
    type: {
      type: String,
      default: 'default',
    },
    fullWidth: Boolean,
    user: { // only for storybook, DO NOT USE
      type: Object,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classes() {
      return `concierge-only-access concierge-only-access--${this.type} ${this.fullWidth && 'concierge-only-access--full-width'}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.concierge-only-access {
  color: var(--fuchsia-80);
  border-radius: var(--radius-big);

  & > * {
    display: inline-block;
  }

  &--default {
    display: flex;
    flex-direction: row;
    padding: calc(var(--grid-unit) * 2);
    text-align: center;
    text-align: left;
    background: var(--fuchsia-5);
  }

  &--inline > * {
    color: var(--fuchsia-80);
  }

  &--button {
    width: fit-content;
    overflow: hidden;
  }

  &--full-width {
    display: block;
    width: 100%;

    & > div {
      width: 100%;
    }
  }

  &--box {
    padding: calc(var(--grid-unit) * 2);
    color: var(--fuchsia-80);
    background: var(--fuchsia-5);
    border: 1px solid var(--fuchsia-50);
    border-radius: var(--radius);
  }

  .fa-eye {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: calc(var(--grid-unit) * 3);
    height: calc(var(--grid-unit) * 3);
    margin-top: 2px;
    margin-right: calc(var(--grid-unit) * 2);
    font-size: var(--font-icon-large);
    color: var(--fuchsia-80);
    background-color: var(--fuchsia-20);
    border-radius: 100%;
  }
}
</style>
