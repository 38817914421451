<template>
  <div v-if="displayCenterOffersBanner">
    <center-offers-banner
      v-for="detail in centerSeatOfferDetails"
      :key="detail.seatId"
      :centerSeatOfferDetail="detail"
    />
  </div>
</template>

<script>
import profileComponentMixin from '@profile/mixins/profile-component';
import CenterOffersBanner from '@components/facility-profile/offers/center-offers-banner.vue';

export default {
  name: 'profile-spot-offer',

  mixins: [
    profileComponentMixin,
  ],

  components: {
    CenterOffersBanner,
  },

  async beforeMount() {
    await this.loadFacility();
    await this.fetchWaitlistSpotCenters();
    await this.fetchCenterSeatOfferDetails();
  },

  computed: {
    centerSeatOfferDetails() {
      return this.$store.state.legup.seatOffersForParent;
    },

    displayCenterOffersBanner() {
      return this.centerSeatOfferDetails.length;
    },
  },

  methods: {
    async fetchCenterSeatOfferDetails() {
      if (!this.isAuthorized || !this.provider.currFacility.legupCenterId) {
        return;
      }

      await this.$store.dispatch('legup/fetchSeatOffersForParent', { center_id: this.provider.currFacility.legupCenterId });
    },

    async fetchWaitlistSpotCenters() {
      if (!this.isAuthorized) {
        return;
      }

      this.waitlistSpotCenters = await this.$store.dispatch('getWaitlistSpotsWithCenters');
    },
  },
};
</script>

<style lang="scss" scoped>
.offers {
  margin-bottom: calc(var(--grid-unit) * 6);
}
</style>
