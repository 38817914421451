<template>
  <div class="combo-input" :class="{'combo-input--error': error}">
    <slot></slot>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.combo-input {
  display: inline-flex;
  align-items: center;
  height: calc(var(--grid-unit) * 5);
  padding: calc(var(--grid-unit) / 2) var(--grid-unit);
  overflow: hidden;
  background: var(--gray-0);
  border: 1px solid var(--gray-80);
  border-radius: var(--radius);

  & > *:not(:last-child) {
    margin-right: var(--grid-unit);
  }

  &:focus-within,
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--input-focus-color);
  }

  &--error {
    color: var(--rust-50);
    box-shadow: 0 0 0 2px var(--rust-10);
  }

  &::v-deep .k-input,
  &::v-deep .input-wrapper {
    border: none;
    box-shadow: none;
  }

  &::v-deep .k-input__input-field,
  &::v-deep .k-input__formatted-value,
  &::v-deep .combo-input {
    height: unset;
    padding: 0;
    border: none;
    box-shadow: none;
  }
}
</style>
