<template>
  <div class="mb-3">
    <box :big-radius="true" color="periwinkle-light" class="legup-waitlist-joined">
      <h3 class="font-heading-20">Waitlist joined</h3>
      <p>We'll let you know when the provider has a spot for you.</p>
      <router-link to="/my-programs" class="link-periwinkle">
        View my waitlists
      </router-link>
    </box>
  </div>
</template>

<script>
import Box from '@components/box.vue';

export default {
  components: {
    Box,
  },
};
</script>

<style lang="scss" scoped>
.legup-waitlist-joined {
  color: var(--gray-80);
  text-align: center;
}

.link-periwinkle {
  color: var(--periwinkle-50);
}
</style>
