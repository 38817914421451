import c3rm from '@stores/modules/c3rm';
import makeProvider from '../../common/provider/provider';

function getDefaultFormData(user) {
  return {
    parentData: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  };
}

export default (store) => makeProvider({
  data() {
    return {
      name: 'vuex-provider',
      isTourModalOpen: false,
      currFacility: undefined,
      tour: undefined,
      tourMode: undefined,
      slots: {},
      slotsPage: 0,
      noTours: false,
      timezone: undefined,
      isEnrollmentModalOpen: false,
      programToEnroll: undefined,
      infoRequestSubmittedSuccesfully: false,
      isFavorited: false,
      legupCenterId: undefined,
    };
  },
  computed: {
    currFacilityFriendlyId() {
      return this.currFacility?.friendlyId;
    },
    isCurrFacilityFavorited() {
      return store.getters['facilities/facilityIsFavorited'](this.currFacility?.id);
    },
    isCurrFacilitySaved() {
      return store.getters['facilities/facilityIsSaved'](this.currFacility?.id);
    },
    isCurrFacilityIgnored() {
      return store.getters['facilities/facilityIsIgnored'](this.currFacility?.id);
    },
    isContactFormRequested() {
      return store.state.autoFocusRequestForm;
    },
    areFavoritedShown() {
      return store.getters.showFavorited;
    },
    dependentIds() {
      return store.getters['dependents/asArray'] || [];
    },
    parentSlots() {
      return store.state.tours && store.state.tours.parentSlots;
    },
    facilityImages() {
      const { profileImages, googleImages } = this.currFacility;
      return [...(profileImages || []), ...((googleImages || []).map((gi) => gi.photoUrl))]
        .slice(0, 4);
    },
    user() {
      return store.state.user.current;
    },
    showLeadForm() {
      return (this.$userAccess && this.$userAccess({ can: 'sendC3rmLead' })) && this.currFacility?.leadFormSupported;
    },
    sourceOptions() {
      return store.state.c3rm?.sourceTypes.map(({ id, label }) => ({ value: id, label })) || [];
    },
    inquiryOptions() {
      return store.state.c3rm?.inquiryTypes.map(({ id, label }) => ({ value: id, label })) || [];
    },
    phoneTypeOptions() {
      return store.state.c3rm?.phoneTypes.map(({ id, label }) => ({ value: id, label })) || [];
    },
    formData() {
      return store.state.c3rm?.formData || getDefaultFormData(store.state.user.current);
    },
    c3rmErrorMessage() {
      return store.state.c3rm?.errorMessage;
    },
    comment() {
      return store.state.facilities.comments[this.currFacility?.id] || this.currFacility?.comment;
    },
    showComment() {
      return this.isCurrFacilityFavorited
      || this.isCurrFacilityIgnored
      || this.isCurrFacilitySaved;
    },
    enrollments() {
      return store.state.user.enrollments
        .filter((e) => e.childCareFacility.id === this.currFacility.id);
    },
    requestHistory() {
      return store.state.user.requestInfoHistory || undefined;
    },
  },
  methods: {
    async loadFacility(friendlyId) {
      if (friendlyId === this.currFacilityFriendlyId) {
        return;
      }
      try {
        const { fetchedFacility, tour } = await store.dispatch('loadFacility', friendlyId);
        this.currFacility = fetchedFacility;
        this.timezone = fetchedFacility.timezone;
        this.tour = tour;
        this.isFavorited = this.isCurrFacilityFavorited;
        this.infoRequestSubmittedSuccesfully = false;
        this.lastMessageDate = fetchedFacility.lastMessageDate;
      } catch (error) {
        store.dispatch('notifications/addToastError', { text: 'Could not fetch facility data', error });
      }
    },
    setState({ isContactFormRequested, ...newState }) {
      this.$data = { ...this.$data, ...newState };
      store.commit('setAutoFocusRequestForm', isContactFormRequested);
    },
    trackEvent(event) {
      return store.dispatch('track/event', event);
    },
    async toggleOpinion(opinion) {
      if (await store.dispatch('hasImpersonationError')) return;

      store.dispatch(`facilities/toggle${opinion}`, {
        facilityId: this.currFacility?.id,
        context: 'facility',
      });
    },
    addToFavorited() {
      if (!this.isFavorited) {
        this.toggleFavorited();
        this.isFavorited = true;
      }
    },
    async fetchSlots() {
      const { tourSlots, noTours, page } = await store.dispatch('fetchFacilitySlots', { currentPage: this.slotsPage, facilityId: this.currFacility.id });
      const slots = { ...this.slots };
      Object.keys(tourSlots).forEach((day) => {
        if (!slots[day]) {
          slots[day] = tourSlots[day] || [];
        } else {
          slots[day] = [...slots[day], ...tourSlots[day]];
        }
      });
      this.slots = slots;
      this.slotsPage = page;
      this.noTours = noTours;
    },
    async fetchFacilityTourMode() {
      this.tourMode = await store.dispatch('fetchFacilityTourMode', this.currFacility?.id);
    },
    fetchParentSlots() {
      return store.dispatch('tours/fetchParentSlots', { timezone: this.timezone });
    },
    async updateParentSlots(slots) {
      if (await store.dispatch('hasImpersonationError')) return;

      store.dispatch('tours/updateParentSlots', { newSlots: slots, timezone: this.timezone });
    },
    async reserveTour(tourData) {
      if (await store.dispatch('hasImpersonationError')) return false;

      const { tour, redirectUrl } = await store.dispatch('tours/reserveTour', { tourData, facilityId: this.currFacility.id });
      this.tour = tour;
      return redirectUrl;
    },
    openScheduleTourModal() {
      this.isTourModalOpen = true;
    },
    closeScheduleTourModal() {
      this.isTourModalOpen = false;
    },
    async getLeadFormData() {
      await store.dispatch('isInitialized');

      if (!this.showLeadForm) {
        return {};
      }

      if (!store.hasModule('c3rm')) {
        await store.registerModule('c3rm', c3rm);
      }

      await store.dispatch('c3rm/getLeadFormData', this.currFacility.id);
      return store.state.c3rm?.formData || getDefaultFormData(store.state.user.current);
    },
    async sendLeadFormData(formData) {
      if (!this.showLeadForm) {
        store.dispatch('notifications/addToastError', { text: 'Could not send lead' });
        return;
      }
      await store.dispatch('c3rm/sendLeadFormData', formData);
    },
    async saveComment(comment) {
      if (await store.dispatch('hasImpersonationError')) return;

      const savedComment = await store.dispatch('facilities/updateComment', {
        comment,
        facilityId: this.currFacility?.id,
        context: 'facility',
      });
      this.currFacility.comment = savedComment;
    },

    async requestInformation({ message, filters }) {
      if (await store.dispatch('hasImpersonationError')) return false;

      if (filters) {
        await store.dispatch('search/runSearch', { filters });
      }
      await store.dispatch('user/requestInformation', { facilityId: this.currFacility.id, message });
      return true;
    },

    openChat() {
      return store.dispatch('openChat');
    },

    openEnrollmentModal(programId) {
      this.isEnrollmentModalOpen = true;
      this.programToEnroll = programId;
    },

    closeEnrollmentModal() {
      this.isEnrollmentModalOpen = false;
      this.programToEnroll = undefined;
    },

    async sendEnrollment(data) {
      if (await store.dispatch('hasImpersonationError')) return;

      const enrollmentData = { ...data, childCareFacilityId: this.currFacility.id };
      store.dispatch('user/sendEnrollment', enrollmentData);
    },

    addToastError(text) {
      return store.dispatch('notifications/addToastError', { text });
    },
  },
});
